"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryRewriteInputDataProductsToJSON = exports.QueryRewriteInputDataProductsFromJSONTyped = exports.QueryRewriteInputDataProductsFromJSON = void 0;
var _1 = require("./");
function QueryRewriteInputDataProductsFromJSON(json) {
    return QueryRewriteInputDataProductsFromJSONTyped(json, false);
}
exports.QueryRewriteInputDataProductsFromJSON = QueryRewriteInputDataProductsFromJSON;
function QueryRewriteInputDataProductsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'columns': (json['columns'].map(_1.QueryRewriteInputColumnsFromJSON)),
        'tableName': json['tableName'],
    };
}
exports.QueryRewriteInputDataProductsFromJSONTyped = QueryRewriteInputDataProductsFromJSONTyped;
function QueryRewriteInputDataProductsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'columns': (value.columns.map(_1.QueryRewriteInputColumnsToJSON)),
        'tableName': value.tableName,
    };
}
exports.QueryRewriteInputDataProductsToJSON = QueryRewriteInputDataProductsToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataSetPreviewSchemaToJSON = exports.DataSetPreviewSchemaFromJSONTyped = exports.DataSetPreviewSchemaFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function DataSetPreviewSchemaFromJSON(json) {
    return DataSetPreviewSchemaFromJSONTyped(json, false);
}
exports.DataSetPreviewSchemaFromJSON = DataSetPreviewSchemaFromJSON;
function DataSetPreviewSchemaFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dataType': json['dataType'],
        'elementType': !runtime_1.exists(json, 'elementType') ? undefined : DataSetPreviewSchemaFromJSON(json['elementType']),
        'fields': !runtime_1.exists(json, 'fields') ? undefined : (json['fields'].map(_1.DataSetPreviewNamedSchemaFromJSON)),
    };
}
exports.DataSetPreviewSchemaFromJSONTyped = DataSetPreviewSchemaFromJSONTyped;
function DataSetPreviewSchemaToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dataType': value.dataType,
        'elementType': DataSetPreviewSchemaToJSON(value.elementType),
        'fields': value.fields === undefined ? undefined : (value.fields.map(_1.DataSetPreviewNamedSchemaToJSON)),
    };
}
exports.DataSetPreviewSchemaToJSON = DataSetPreviewSchemaToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupToJSON = exports.GroupFromJSONTyped = exports.GroupFromJSON = void 0;
var runtime_1 = require("../runtime");
function GroupFromJSON(json) {
    return GroupFromJSONTyped(json, false);
}
exports.GroupFromJSON = GroupFromJSON;
function GroupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'apiAccessPolicyIds': json['apiAccessPolicyIds'],
        'autoAssignUsers': !runtime_1.exists(json, 'autoAssignUsers') ? undefined : json['autoAssignUsers'],
        'claims': json['claims'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'groupId': json['groupId'],
        'members': json['members'],
    };
}
exports.GroupFromJSONTyped = GroupFromJSONTyped;
function GroupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'apiAccessPolicyIds': value.apiAccessPolicyIds,
        'autoAssignUsers': value.autoAssignUsers,
        'claims': value.claims,
        'description': value.description,
        'groupId': value.groupId,
        'members': value.members,
    };
}
exports.GroupToJSON = GroupToJSON;

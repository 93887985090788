"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataProductPreviewToJSON = exports.DataProductPreviewFromJSONTyped = exports.DataProductPreviewFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function DataProductPreviewFromJSON(json) {
    return DataProductPreviewFromJSONTyped(json, false);
}
exports.DataProductPreviewFromJSON = DataProductPreviewFromJSON;
function DataProductPreviewFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'durationMilliseconds': !runtime_1.exists(json, 'durationMilliseconds') ? undefined : json['durationMilliseconds'],
        'error': !runtime_1.exists(json, 'error') ? undefined : _1.ApiErrorFromJSON(json['error']),
        'initialDataSets': !runtime_1.exists(json, 'initialDataSets') ? undefined : (runtime_1.mapValues(json['initialDataSets'], _1.DataSetPreviewFromJSON)),
        'previewId': json['previewId'],
        'status': json['status'],
        'transformedDataSets': !runtime_1.exists(json, 'transformedDataSets') ? undefined : (runtime_1.mapValues(json['transformedDataSets'], _1.DataSetPreviewFromJSON)),
        'transformsApplied': !runtime_1.exists(json, 'transformsApplied') ? undefined : _1.DataProductTransformsFromJSON(json['transformsApplied']),
    };
}
exports.DataProductPreviewFromJSONTyped = DataProductPreviewFromJSONTyped;
function DataProductPreviewToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'durationMilliseconds': value.durationMilliseconds,
        'error': _1.ApiErrorToJSON(value.error),
        'initialDataSets': value.initialDataSets === undefined ? undefined : (runtime_1.mapValues(value.initialDataSets, _1.DataSetPreviewToJSON)),
        'previewId': value.previewId,
        'status': value.status,
        'transformedDataSets': value.transformedDataSets === undefined ? undefined : (runtime_1.mapValues(value.transformedDataSets, _1.DataSetPreviewToJSON)),
        'transformsApplied': _1.DataProductTransformsToJSON(value.transformsApplied),
    };
}
exports.DataProductPreviewToJSON = DataProductPreviewToJSON;

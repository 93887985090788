"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneratedQueryToJSON = exports.GeneratedQueryFromJSONTyped = exports.GeneratedQueryFromJSON = void 0;
var _1 = require("./");
function GeneratedQueryFromJSON(json) {
    return GeneratedQueryFromJSONTyped(json, false);
}
exports.GeneratedQueryFromJSON = GeneratedQueryFromJSON;
function GeneratedQueryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dataProducts': (json['dataProducts'].map(_1.DataProductFromJSON)),
        'governedDataSets': _1.GovernedDataSetsFromJSON(json['governedDataSets']),
        'query': json['query'],
    };
}
exports.GeneratedQueryFromJSONTyped = GeneratedQueryFromJSONTyped;
function GeneratedQueryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dataProducts': (value.dataProducts.map(_1.DataProductToJSON)),
        'governedDataSets': _1.GovernedDataSetsToJSON(value.governedDataSets),
        'query': value.query,
    };
}
exports.GeneratedQueryToJSON = GeneratedQueryToJSON;

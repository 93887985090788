"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListDataProductScriptsResponseToJSON = exports.ListDataProductScriptsResponseFromJSONTyped = exports.ListDataProductScriptsResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ListDataProductScriptsResponseFromJSON(json) {
    return ListDataProductScriptsResponseFromJSONTyped(json, false);
}
exports.ListDataProductScriptsResponseFromJSON = ListDataProductScriptsResponseFromJSON;
function ListDataProductScriptsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nextToken': !runtime_1.exists(json, 'nextToken') ? undefined : json['nextToken'],
        'scripts': (json['scripts'].map(_1.ScriptFromJSON)),
        'totalItems': !runtime_1.exists(json, 'totalItems') ? undefined : json['totalItems'],
    };
}
exports.ListDataProductScriptsResponseFromJSONTyped = ListDataProductScriptsResponseFromJSONTyped;
function ListDataProductScriptsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nextToken': value.nextToken,
        'scripts': (value.scripts.map(_1.ScriptToJSON)),
        'totalItems': value.totalItems,
    };
}
exports.ListDataProductScriptsResponseToJSON = ListDataProductScriptsResponseToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCostOutputToJSON = exports.GetCostOutputFromJSONTyped = exports.GetCostOutputFromJSON = void 0;
var _1 = require("./");
function GetCostOutputFromJSON(json) {
    return GetCostOutputFromJSONTyped(json, false);
}
exports.GetCostOutputFromJSON = GetCostOutputFromJSON;
function GetCostOutputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dimensionValueAttributes': (json['dimensionValueAttributes'].map(_1.GetCostOutputDimensionValueAttributesFromJSON)),
        'groupDefinitions': (json['groupDefinitions'].map(_1.GetCostOutputGroupDefinitionsFromJSON)),
        'resultsByTime': (json['resultsByTime'].map(_1.GetCostOutputResultsByTimeFromJSON)),
    };
}
exports.GetCostOutputFromJSONTyped = GetCostOutputFromJSONTyped;
function GetCostOutputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dimensionValueAttributes': (value.dimensionValueAttributes.map(_1.GetCostOutputDimensionValueAttributesToJSON)),
        'groupDefinitions': (value.groupDefinitions.map(_1.GetCostOutputGroupDefinitionsToJSON)),
        'resultsByTime': (value.resultsByTime.map(_1.GetCostOutputResultsByTimeToJSON)),
    };
}
exports.GetCostOutputToJSON = GetCostOutputToJSON;

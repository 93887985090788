"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionToJSON = exports.PermissionFromJSONTyped = exports.PermissionFromJSON = void 0;
var runtime_1 = require("../runtime");
function PermissionFromJSON(json) {
    return PermissionFromJSONTyped(json, false);
}
exports.PermissionFromJSON = PermissionFromJSON;
function PermissionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'access': !runtime_1.exists(json, 'access') ? undefined : json['access'],
    };
}
exports.PermissionFromJSONTyped = PermissionFromJSONTyped;
function PermissionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'access': value.access,
    };
}
exports.PermissionToJSON = PermissionToJSON;

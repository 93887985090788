"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersistedNotificationIdentifierToJSON = exports.PersistedNotificationIdentifierFromJSONTyped = exports.PersistedNotificationIdentifierFromJSON = void 0;
function PersistedNotificationIdentifierFromJSON(json) {
    return PersistedNotificationIdentifierFromJSONTyped(json, false);
}
exports.PersistedNotificationIdentifierFromJSON = PersistedNotificationIdentifierFromJSON;
function PersistedNotificationIdentifierFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'notificationId': json['notificationId'],
        'target': json['target'],
    };
}
exports.PersistedNotificationIdentifierFromJSONTyped = PersistedNotificationIdentifierFromJSONTyped;
function PersistedNotificationIdentifierToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'notificationId': value.notificationId,
        'target': value.target,
    };
}
exports.PersistedNotificationIdentifierToJSON = PersistedNotificationIdentifierToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SavedQueryEntityToJSON = exports.SavedQueryEntityFromJSONTyped = exports.SavedQueryEntityFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function SavedQueryEntityFromJSON(json) {
    return SavedQueryEntityFromJSONTyped(json, false);
}
exports.SavedQueryEntityFromJSON = SavedQueryEntityFromJSON;
function SavedQueryEntityFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'addressedAs': json['addressedAs'],
        'createdBy': !runtime_1.exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdTimestamp': !runtime_1.exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'namespace': json['namespace'],
        'query': json['query'],
        'queryId': json['queryId'],
        'referencedDataSets': (json['referencedDataSets'].map(_1.ReferencedDataSetIdentifierFromJSON)),
        'referencedQueries': (json['referencedQueries'].map(_1.SavedQueryIdentifierFromJSON)),
        'tags': !runtime_1.exists(json, 'tags') ? undefined : _1.TagsFromJSON(json['tags']),
        'type': json['type'],
        'updatedBy': !runtime_1.exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'updatedTimestamp': !runtime_1.exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
    };
}
exports.SavedQueryEntityFromJSONTyped = SavedQueryEntityFromJSONTyped;
function SavedQueryEntityToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'addressedAs': value.addressedAs,
        'createdBy': value.createdBy,
        'createdTimestamp': value.createdTimestamp,
        'description': value.description,
        'namespace': value.namespace,
        'query': value.query,
        'queryId': value.queryId,
        'referencedDataSets': (value.referencedDataSets.map(_1.ReferencedDataSetIdentifierToJSON)),
        'referencedQueries': (value.referencedQueries.map(_1.SavedQueryIdentifierToJSON)),
        'tags': _1.TagsToJSON(value.tags),
        'type': value.type,
        'updatedBy': value.updatedBy,
        'updatedTimestamp': value.updatedTimestamp,
    };
}
exports.SavedQueryEntityToJSON = SavedQueryEntityToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteGovernancePolicyAttributesRequestToJSON = exports.DeleteGovernancePolicyAttributesRequestFromJSONTyped = exports.DeleteGovernancePolicyAttributesRequestFromJSON = void 0;
var _1 = require("./");
function DeleteGovernancePolicyAttributesRequestFromJSON(json) {
    return DeleteGovernancePolicyAttributesRequestFromJSONTyped(json, false);
}
exports.DeleteGovernancePolicyAttributesRequestFromJSON = DeleteGovernancePolicyAttributesRequestFromJSON;
function DeleteGovernancePolicyAttributesRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'policies': (json['policies'].map(_1.AttributePolicyIdentifierFromJSON)),
    };
}
exports.DeleteGovernancePolicyAttributesRequestFromJSONTyped = DeleteGovernancePolicyAttributesRequestFromJSONTyped;
function DeleteGovernancePolicyAttributesRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'policies': (value.policies.map(_1.AttributePolicyIdentifierToJSON)),
    };
}
exports.DeleteGovernancePolicyAttributesRequestToJSON = DeleteGovernancePolicyAttributesRequestToJSON;

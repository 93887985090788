"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostQueryParseRenderDiscoverResponseToJSON = exports.PostQueryParseRenderDiscoverResponseFromJSONTyped = exports.PostQueryParseRenderDiscoverResponseFromJSON = void 0;
var _1 = require("./");
function PostQueryParseRenderDiscoverResponseFromJSON(json) {
    return PostQueryParseRenderDiscoverResponseFromJSONTyped(json, false);
}
exports.PostQueryParseRenderDiscoverResponseFromJSON = PostQueryParseRenderDiscoverResponseFromJSON;
function PostQueryParseRenderDiscoverResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tables': (json['tables'].map(_1.PostQueryParseRenderDiscoverResponseTablesFromJSON)),
    };
}
exports.PostQueryParseRenderDiscoverResponseFromJSONTyped = PostQueryParseRenderDiscoverResponseFromJSONTyped;
function PostQueryParseRenderDiscoverResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tables': (value.tables.map(_1.PostQueryParseRenderDiscoverResponseTablesToJSON)),
    };
}
exports.PostQueryParseRenderDiscoverResponseToJSON = PostQueryParseRenderDiscoverResponseToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OntologyToJSON = exports.OntologyFromJSONTyped = exports.OntologyFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function OntologyFromJSON(json) {
    return OntologyFromJSONTyped(json, false);
}
exports.OntologyFromJSON = OntologyFromJSON;
function OntologyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aliases': (json['aliases'].map(_1.OntologyAliasFromJSON)),
        'defaultLens': !runtime_1.exists(json, 'defaultLens') ? undefined : _1.LensEnumFromJSON(json['defaultLens']),
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'name': json['name'],
        'ontologyId': json['ontologyId'],
        'ontologyNamespace': json['ontologyNamespace'],
    };
}
exports.OntologyFromJSONTyped = OntologyFromJSONTyped;
function OntologyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aliases': (value.aliases.map(_1.OntologyAliasToJSON)),
        'defaultLens': _1.LensEnumToJSON(value.defaultLens),
        'description': value.description,
        'name': value.name,
        'ontologyId': value.ontologyId,
        'ontologyNamespace': value.ontologyNamespace,
    };
}
exports.OntologyToJSON = OntologyToJSON;

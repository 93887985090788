"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryStatusToJSON = exports.QueryStatusFromJSONTyped = exports.QueryStatusFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function QueryStatusFromJSON(json) {
    return QueryStatusFromJSONTyped(json, false);
}
exports.QueryStatusFromJSON = QueryStatusFromJSON;
function QueryStatusFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'reason': !runtime_1.exists(json, 'reason') ? undefined : json['reason'],
        'status': _1.QueryExecutionStatusEnumFromJSON(json['status']),
    };
}
exports.QueryStatusFromJSONTyped = QueryStatusFromJSONTyped;
function QueryStatusToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'reason': value.reason,
        'status': _1.QueryExecutionStatusEnumToJSON(value.status),
    };
}
exports.QueryStatusToJSON = QueryStatusToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupInputToJSON = exports.GroupInputFromJSONTyped = exports.GroupInputFromJSON = void 0;
var runtime_1 = require("../runtime");
function GroupInputFromJSON(json) {
    return GroupInputFromJSONTyped(json, false);
}
exports.GroupInputFromJSON = GroupInputFromJSON;
function GroupInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'apiAccessPolicyIds': json['apiAccessPolicyIds'],
        'autoAssignUsers': !runtime_1.exists(json, 'autoAssignUsers') ? undefined : json['autoAssignUsers'],
        'claims': json['claims'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'groupId': !runtime_1.exists(json, 'groupId') ? undefined : json['groupId'],
        'members': !runtime_1.exists(json, 'members') ? undefined : json['members'],
        'updatedTimestamp': !runtime_1.exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
    };
}
exports.GroupInputFromJSONTyped = GroupInputFromJSONTyped;
function GroupInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'apiAccessPolicyIds': value.apiAccessPolicyIds,
        'autoAssignUsers': value.autoAssignUsers,
        'claims': value.claims,
        'description': value.description,
        'groupId': value.groupId,
        'members': value.members,
        'updatedTimestamp': value.updatedTimestamp,
    };
}
exports.GroupInputToJSON = GroupInputToJSON;

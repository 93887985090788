"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCostOutputTimePeriodToJSON = exports.GetCostOutputTimePeriodFromJSONTyped = exports.GetCostOutputTimePeriodFromJSON = void 0;
var runtime_1 = require("../runtime");
function GetCostOutputTimePeriodFromJSON(json) {
    return GetCostOutputTimePeriodFromJSONTyped(json, false);
}
exports.GetCostOutputTimePeriodFromJSON = GetCostOutputTimePeriodFromJSON;
function GetCostOutputTimePeriodFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'end': !runtime_1.exists(json, 'end') ? undefined : json['end'],
        'start': !runtime_1.exists(json, 'start') ? undefined : json['start'],
    };
}
exports.GetCostOutputTimePeriodFromJSONTyped = GetCostOutputTimePeriodFromJSONTyped;
function GetCostOutputTimePeriodToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'end': value.end,
        'start': value.start,
    };
}
exports.GetCostOutputTimePeriodToJSON = GetCostOutputTimePeriodToJSON;

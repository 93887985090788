"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryHistoryToJSON = exports.QueryHistoryFromJSONTyped = exports.QueryHistoryFromJSON = void 0;
function QueryHistoryFromJSON(json) {
    return QueryHistoryFromJSONTyped(json, false);
}
exports.QueryHistoryFromJSON = QueryHistoryFromJSON;
function QueryHistoryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'executionId': json['executionId'],
        'query': json['query'],
    };
}
exports.QueryHistoryFromJSONTyped = QueryHistoryFromJSONTyped;
function QueryHistoryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'executionId': value.executionId,
        'query': value.query,
    };
}
exports.QueryHistoryToJSON = QueryHistoryToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScriptVulnerabilityReportResultsToJSON = exports.ScriptVulnerabilityReportResultsFromJSONTyped = exports.ScriptVulnerabilityReportResultsFromJSON = void 0;
var _1 = require("./");
function ScriptVulnerabilityReportResultsFromJSON(json) {
    return ScriptVulnerabilityReportResultsFromJSONTyped(json, false);
}
exports.ScriptVulnerabilityReportResultsFromJSON = ScriptVulnerabilityReportResultsFromJSON;
function ScriptVulnerabilityReportResultsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'code': json['code'],
        'columnOffset': json['columnOffset'],
        'cwe': _1.ScriptVulnerabilityReportCweFromJSON(json['cwe']),
        'filename': json['filename'],
        'issueConfidence': _1.ScriptVulnerabilityLevelFromJSON(json['issueConfidence']),
        'issueSeverity': _1.ScriptVulnerabilityLevelFromJSON(json['issueSeverity']),
        'issueText': json['issueText'],
        'lineNumber': json['lineNumber'],
        'lineRange': json['lineRange'],
        'moreInfo': json['moreInfo'],
        'testId': json['testId'],
        'testName': json['testName'],
    };
}
exports.ScriptVulnerabilityReportResultsFromJSONTyped = ScriptVulnerabilityReportResultsFromJSONTyped;
function ScriptVulnerabilityReportResultsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'code': value.code,
        'columnOffset': value.columnOffset,
        'cwe': _1.ScriptVulnerabilityReportCweToJSON(value.cwe),
        'filename': value.filename,
        'issueConfidence': _1.ScriptVulnerabilityLevelToJSON(value.issueConfidence),
        'issueSeverity': _1.ScriptVulnerabilityLevelToJSON(value.issueSeverity),
        'issueText': value.issueText,
        'lineNumber': value.lineNumber,
        'lineRange': value.lineRange,
        'moreInfo': value.moreInfo,
        'testId': value.testId,
        'testName': value.testName,
    };
}
exports.ScriptVulnerabilityReportResultsToJSON = ScriptVulnerabilityReportResultsToJSON;

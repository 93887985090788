"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCostOutputResultsByTimeToJSON = exports.GetCostOutputResultsByTimeFromJSONTyped = exports.GetCostOutputResultsByTimeFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GetCostOutputResultsByTimeFromJSON(json) {
    return GetCostOutputResultsByTimeFromJSONTyped(json, false);
}
exports.GetCostOutputResultsByTimeFromJSON = GetCostOutputResultsByTimeFromJSON;
function GetCostOutputResultsByTimeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'estimated': !runtime_1.exists(json, 'estimated') ? undefined : json['estimated'],
        'groups': !runtime_1.exists(json, 'groups') ? undefined : (json['groups'].map(_1.GetCostOutputGroupsFromJSON)),
        'timePeriod': !runtime_1.exists(json, 'timePeriod') ? undefined : _1.GetCostOutputTimePeriodFromJSON(json['timePeriod']),
        'total': !runtime_1.exists(json, 'total') ? undefined : _1.GetCostOutputTotalFromJSON(json['total']),
    };
}
exports.GetCostOutputResultsByTimeFromJSONTyped = GetCostOutputResultsByTimeFromJSONTyped;
function GetCostOutputResultsByTimeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'estimated': value.estimated,
        'groups': value.groups === undefined ? undefined : (value.groups.map(_1.GetCostOutputGroupsToJSON)),
        'timePeriod': _1.GetCostOutputTimePeriodToJSON(value.timePeriod),
        'total': _1.GetCostOutputTotalToJSON(value.total),
    };
}
exports.GetCostOutputResultsByTimeToJSON = GetCostOutputResultsByTimeToJSON;

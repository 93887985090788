"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataProductUpdateTriggerToJSON = exports.DataProductUpdateTriggerFromJSONTyped = exports.DataProductUpdateTriggerFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function DataProductUpdateTriggerFromJSON(json) {
    return DataProductUpdateTriggerFromJSONTyped(json, false);
}
exports.DataProductUpdateTriggerFromJSON = DataProductUpdateTriggerFromJSON;
function DataProductUpdateTriggerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'scheduleRate': !runtime_1.exists(json, 'scheduleRate') ? undefined : json['scheduleRate'],
        'triggerType': _1.DataProductUpdateTriggerTypeFromJSON(json['triggerType']),
        'updatePolicy': !runtime_1.exists(json, 'updatePolicy') ? undefined : _1.DataProductUpdatePolicyTypeFromJSON(json['updatePolicy']),
    };
}
exports.DataProductUpdateTriggerFromJSONTyped = DataProductUpdateTriggerFromJSONTyped;
function DataProductUpdateTriggerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'scheduleRate': value.scheduleRate,
        'triggerType': _1.DataProductUpdateTriggerTypeToJSON(value.triggerType),
        'updatePolicy': _1.DataProductUpdatePolicyTypeToJSON(value.updatePolicy),
    };
}
exports.DataProductUpdateTriggerToJSON = DataProductUpdateTriggerToJSON;

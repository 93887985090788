"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationToJSON = exports.NotificationFromJSONTyped = exports.NotificationFromJSON = void 0;
var runtime_1 = require("../runtime");
function NotificationFromJSON(json) {
    return NotificationFromJSONTyped(json, false);
}
exports.NotificationFromJSON = NotificationFromJSON;
function NotificationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'payload': json['payload'],
        'source': json['source'],
        'target': !runtime_1.exists(json, 'target') ? undefined : json['target'],
        'type': json['type'],
    };
}
exports.NotificationFromJSONTyped = NotificationFromJSONTyped;
function NotificationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'payload': value.payload,
        'source': value.source,
        'target': value.target,
        'type': value.type,
    };
}
exports.NotificationToJSON = NotificationToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataSetsToJSON = exports.DataSetsFromJSONTyped = exports.DataSetsFromJSON = void 0;
function DataSetsFromJSON(json) {
    return DataSetsFromJSONTyped(json, false);
}
exports.DataSetsFromJSON = DataSetsFromJSON;
function DataSetsFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.DataSetsFromJSONTyped = DataSetsFromJSONTyped;
function DataSetsToJSON(value) {
    return value;
}
exports.DataSetsToJSON = DataSetsToJSON;

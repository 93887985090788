"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SavedQueryToJSON = exports.SavedQueryFromJSONTyped = exports.SavedQueryFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function SavedQueryFromJSON(json) {
    return SavedQueryFromJSONTyped(json, false);
}
exports.SavedQueryFromJSON = SavedQueryFromJSON;
function SavedQueryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'addressedAs': json['addressedAs'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'namespace': json['namespace'],
        'query': json['query'],
        'queryId': json['queryId'],
        'referencedDataSets': (json['referencedDataSets'].map(_1.ReferencedDataSetIdentifierFromJSON)),
        'referencedQueries': (json['referencedQueries'].map(_1.SavedQueryIdentifierFromJSON)),
        'tags': !runtime_1.exists(json, 'tags') ? undefined : _1.TagsFromJSON(json['tags']),
        'type': json['type'],
    };
}
exports.SavedQueryFromJSONTyped = SavedQueryFromJSONTyped;
function SavedQueryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'addressedAs': value.addressedAs,
        'description': value.description,
        'namespace': value.namespace,
        'query': value.query,
        'queryId': value.queryId,
        'referencedDataSets': (value.referencedDataSets.map(_1.ReferencedDataSetIdentifierToJSON)),
        'referencedQueries': (value.referencedQueries.map(_1.SavedQueryIdentifierToJSON)),
        'tags': _1.TagsToJSON(value.tags),
        'type': value.type,
    };
}
exports.SavedQueryToJSON = SavedQueryToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataProductAccessLevelToJSON = exports.DataProductAccessLevelFromJSONTyped = exports.DataProductAccessLevelFromJSON = void 0;
var _1 = require("./");
function DataProductAccessLevelFromJSON(json) {
    return DataProductAccessLevelFromJSONTyped(json, false);
}
exports.DataProductAccessLevelFromJSON = DataProductAccessLevelFromJSON;
function DataProductAccessLevelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'access': _1.AccessEnumFromJSON(json['access']),
    };
}
exports.DataProductAccessLevelFromJSONTyped = DataProductAccessLevelFromJSONTyped;
function DataProductAccessLevelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'access': _1.AccessEnumToJSON(value.access),
    };
}
exports.DataProductAccessLevelToJSON = DataProductAccessLevelToJSON;

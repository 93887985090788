"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataIntegrityEnumToJSON = exports.DataIntegrityEnumFromJSONTyped = exports.DataIntegrityEnumFromJSON = void 0;
function DataIntegrityEnumFromJSON(json) {
    return DataIntegrityEnumFromJSONTyped(json, false);
}
exports.DataIntegrityEnumFromJSON = DataIntegrityEnumFromJSON;
function DataIntegrityEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.DataIntegrityEnumFromJSONTyped = DataIntegrityEnumFromJSONTyped;
function DataIntegrityEnumToJSON(value) {
    return value;
}
exports.DataIntegrityEnumToJSON = DataIntegrityEnumToJSON;

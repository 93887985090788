"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GovernedDataSetDetailsToJSON = exports.GovernedDataSetDetailsFromJSONTyped = exports.GovernedDataSetDetailsFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GovernedDataSetDetailsFromJSON(json) {
    return GovernedDataSetDetailsFromJSONTyped(json, false);
}
exports.GovernedDataSetDetailsFromJSON = GovernedDataSetDetailsFromJSON;
function GovernedDataSetDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'columnMetadata': _1.GovernedColumnsMetadataFromJSON(json['columnMetadata']),
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'identifiers': _1.DataSetIdentifiersFromJSON(json['identifiers']),
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
    };
}
exports.GovernedDataSetDetailsFromJSONTyped = GovernedDataSetDetailsFromJSONTyped;
function GovernedDataSetDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'columnMetadata': _1.GovernedColumnsMetadataToJSON(value.columnMetadata),
        'description': value.description,
        'identifiers': _1.DataSetIdentifiersToJSON(value.identifiers),
        'name': value.name,
    };
}
exports.GovernedDataSetDetailsToJSON = GovernedDataSetDetailsToJSON;

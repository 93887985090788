"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScriptVulnerabilityReportMetricsConfidenceToJSON = exports.ScriptVulnerabilityReportMetricsConfidenceFromJSONTyped = exports.ScriptVulnerabilityReportMetricsConfidenceFromJSON = void 0;
function ScriptVulnerabilityReportMetricsConfidenceFromJSON(json) {
    return ScriptVulnerabilityReportMetricsConfidenceFromJSONTyped(json, false);
}
exports.ScriptVulnerabilityReportMetricsConfidenceFromJSON = ScriptVulnerabilityReportMetricsConfidenceFromJSON;
function ScriptVulnerabilityReportMetricsConfidenceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'hIGH': json['HIGH'],
        'lOW': json['LOW'],
        'mEDIUM': json['MEDIUM'],
    };
}
exports.ScriptVulnerabilityReportMetricsConfidenceFromJSONTyped = ScriptVulnerabilityReportMetricsConfidenceFromJSONTyped;
function ScriptVulnerabilityReportMetricsConfidenceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'HIGH': value.hIGH,
        'LOW': value.lOW,
        'MEDIUM': value.mEDIUM,
    };
}
exports.ScriptVulnerabilityReportMetricsConfidenceToJSON = ScriptVulnerabilityReportMetricsConfidenceToJSON;

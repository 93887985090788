"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryDownloadToJSON = exports.QueryDownloadFromJSONTyped = exports.QueryDownloadFromJSON = void 0;
function QueryDownloadFromJSON(json) {
    return QueryDownloadFromJSONTyped(json, false);
}
exports.QueryDownloadFromJSON = QueryDownloadFromJSON;
function QueryDownloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'signedUrl': json['signedUrl'],
    };
}
exports.QueryDownloadFromJSONTyped = QueryDownloadFromJSONTyped;
function QueryDownloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'signedUrl': value.signedUrl,
    };
}
exports.QueryDownloadToJSON = QueryDownloadToJSON;

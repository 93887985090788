"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListQueryHistoriesResponseToJSON = exports.ListQueryHistoriesResponseFromJSONTyped = exports.ListQueryHistoriesResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ListQueryHistoriesResponseFromJSON(json) {
    return ListQueryHistoriesResponseFromJSONTyped(json, false);
}
exports.ListQueryHistoriesResponseFromJSON = ListQueryHistoriesResponseFromJSON;
function ListQueryHistoriesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nextToken': !runtime_1.exists(json, 'nextToken') ? undefined : json['nextToken'],
        'queries': (json['queries'].map(_1.QueryHistoryEntityFromJSON)),
        'totalItems': !runtime_1.exists(json, 'totalItems') ? undefined : json['totalItems'],
    };
}
exports.ListQueryHistoriesResponseFromJSONTyped = ListQueryHistoriesResponseFromJSONTyped;
function ListQueryHistoriesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nextToken': value.nextToken,
        'queries': (value.queries.map(_1.QueryHistoryEntityToJSON)),
        'totalItems': value.totalItems,
    };
}
exports.ListQueryHistoriesResponseToJSON = ListQueryHistoriesResponseToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersistedNotificationToJSON = exports.PersistedNotificationFromJSONTyped = exports.PersistedNotificationFromJSON = void 0;
function PersistedNotificationFromJSON(json) {
    return PersistedNotificationFromJSONTyped(json, false);
}
exports.PersistedNotificationFromJSON = PersistedNotificationFromJSON;
function PersistedNotificationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'notificationId': json['notificationId'],
        'payload': json['payload'],
        'source': json['source'],
        'status': json['status'],
        'target': json['target'],
        'targetAndStatus': json['targetAndStatus'],
        'type': json['type'],
    };
}
exports.PersistedNotificationFromJSONTyped = PersistedNotificationFromJSONTyped;
function PersistedNotificationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'notificationId': value.notificationId,
        'payload': value.payload,
        'source': value.source,
        'status': value.status,
        'target': value.target,
        'targetAndStatus': value.targetAndStatus,
        'type': value.type,
    };
}
exports.PersistedNotificationToJSON = PersistedNotificationToJSON;

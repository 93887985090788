"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserEntityToJSON = exports.UserEntityFromJSONTyped = exports.UserEntityFromJSON = void 0;
var runtime_1 = require("../runtime");
function UserEntityFromJSON(json) {
    return UserEntityFromJSONTyped(json, false);
}
exports.UserEntityFromJSON = UserEntityFromJSON;
function UserEntityFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'address': !runtime_1.exists(json, 'address') ? undefined : json['address'],
        'createdBy': !runtime_1.exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdTimestamp': !runtime_1.exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'],
        'customGroups': !runtime_1.exists(json, 'customGroups') ? undefined : json['customGroups'],
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
        'familyName': !runtime_1.exists(json, 'familyName') ? undefined : json['familyName'],
        'givenName': !runtime_1.exists(json, 'givenName') ? undefined : json['givenName'],
        'middleName': !runtime_1.exists(json, 'middleName') ? undefined : json['middleName'],
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'nickname': !runtime_1.exists(json, 'nickname') ? undefined : json['nickname'],
        'phoneNumber': !runtime_1.exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'preferredUsername': !runtime_1.exists(json, 'preferredUsername') ? undefined : json['preferredUsername'],
        'sub': !runtime_1.exists(json, 'sub') ? undefined : json['sub'],
        'updatedBy': !runtime_1.exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'updatedTimestamp': !runtime_1.exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
        'username': json['username'],
    };
}
exports.UserEntityFromJSONTyped = UserEntityFromJSONTyped;
function UserEntityToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'address': value.address,
        'createdBy': value.createdBy,
        'createdTimestamp': value.createdTimestamp,
        'customGroups': value.customGroups,
        'email': value.email,
        'familyName': value.familyName,
        'givenName': value.givenName,
        'middleName': value.middleName,
        'name': value.name,
        'nickname': value.nickname,
        'phoneNumber': value.phoneNumber,
        'preferredUsername': value.preferredUsername,
        'sub': value.sub,
        'updatedBy': value.updatedBy,
        'updatedTimestamp': value.updatedTimestamp,
        'username': value.username,
    };
}
exports.UserEntityToJSON = UserEntityToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataSetPreviewNamedSchemaToJSON = exports.DataSetPreviewNamedSchemaFromJSONTyped = exports.DataSetPreviewNamedSchemaFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function DataSetPreviewNamedSchemaFromJSON(json) {
    return DataSetPreviewNamedSchemaFromJSONTyped(json, false);
}
exports.DataSetPreviewNamedSchemaFromJSON = DataSetPreviewNamedSchemaFromJSON;
function DataSetPreviewNamedSchemaFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'container': !runtime_1.exists(json, 'container') ? undefined : _1.DataSetPreviewSchemaFromJSON(json['container']),
        'name': json['name'],
    };
}
exports.DataSetPreviewNamedSchemaFromJSONTyped = DataSetPreviewNamedSchemaFromJSONTyped;
function DataSetPreviewNamedSchemaToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'container': _1.DataSetPreviewSchemaToJSON(value.container),
        'name': value.name,
    };
}
exports.DataSetPreviewNamedSchemaToJSON = DataSetPreviewNamedSchemaToJSON;

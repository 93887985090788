"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TearDownDetailsToJSON = exports.TearDownDetailsFromJSONTyped = exports.TearDownDetailsFromJSON = void 0;
function TearDownDetailsFromJSON(json) {
    return TearDownDetailsFromJSONTyped(json, false);
}
exports.TearDownDetailsFromJSON = TearDownDetailsFromJSON;
function TearDownDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'coreStackId': json['coreStackId'],
        'message': json['message'],
        'mode': json['mode'],
        'retainedResources': json['retainedResources'],
    };
}
exports.TearDownDetailsFromJSONTyped = TearDownDetailsFromJSONTyped;
function TearDownDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'coreStackId': value.coreStackId,
        'message': value.message,
        'mode': value.mode,
        'retainedResources': value.retainedResources,
    };
}
exports.TearDownDetailsToJSON = TearDownDetailsToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListOntologyFindByAliasesResponseToJSON = exports.ListOntologyFindByAliasesResponseFromJSONTyped = exports.ListOntologyFindByAliasesResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ListOntologyFindByAliasesResponseFromJSON(json) {
    return ListOntologyFindByAliasesResponseFromJSONTyped(json, false);
}
exports.ListOntologyFindByAliasesResponseFromJSON = ListOntologyFindByAliasesResponseFromJSON;
function ListOntologyFindByAliasesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aliases': (runtime_1.mapValues(json['aliases'], _1.OntologyEntityFromJSON)),
        'nextToken': !runtime_1.exists(json, 'nextToken') ? undefined : json['nextToken'],
        'totalItems': !runtime_1.exists(json, 'totalItems') ? undefined : json['totalItems'],
    };
}
exports.ListOntologyFindByAliasesResponseFromJSONTyped = ListOntologyFindByAliasesResponseFromJSONTyped;
function ListOntologyFindByAliasesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aliases': (runtime_1.mapValues(value.aliases, _1.OntologyEntityToJSON)),
        'nextToken': value.nextToken,
        'totalItems': value.totalItems,
    };
}
exports.ListOntologyFindByAliasesResponseToJSON = ListOntologyFindByAliasesResponseToJSON;

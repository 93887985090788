"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataSetPreviewToJSON = exports.DataSetPreviewFromJSONTyped = exports.DataSetPreviewFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function DataSetPreviewFromJSON(json) {
    return DataSetPreviewFromJSONTyped(json, false);
}
exports.DataSetPreviewFromJSON = DataSetPreviewFromJSON;
function DataSetPreviewFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'classification': !runtime_1.exists(json, 'classification') ? undefined : json['classification'],
        'data': json['data'],
        'metadata': !runtime_1.exists(json, 'metadata') ? undefined : json['metadata'],
        's3Path': !runtime_1.exists(json, 's3Path') ? undefined : json['s3Path'],
        's3SamplePath': !runtime_1.exists(json, 's3SamplePath') ? undefined : json['s3SamplePath'],
        'schema': _1.DataSetPreviewSchemaFromJSON(json['schema']),
    };
}
exports.DataSetPreviewFromJSONTyped = DataSetPreviewFromJSONTyped;
function DataSetPreviewToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'classification': value.classification,
        'data': value.data,
        'metadata': value.metadata,
        's3Path': value.s3Path,
        's3SamplePath': value.s3SamplePath,
        'schema': _1.DataSetPreviewSchemaToJSON(value.schema),
    };
}
exports.DataSetPreviewToJSON = DataSetPreviewToJSON;

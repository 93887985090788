"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenInputToJSON = exports.TokenInputFromJSONTyped = exports.TokenInputFromJSON = void 0;
var runtime_1 = require("../runtime");
function TokenInputFromJSON(json) {
    return TokenInputFromJSONTyped(json, false);
}
exports.TokenInputFromJSON = TokenInputFromJSON;
function TokenInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'authToken': !runtime_1.exists(json, 'authToken') ? undefined : json['authToken'],
        'authUrl': !runtime_1.exists(json, 'authUrl') ? undefined : json['authUrl'],
        'clientId': !runtime_1.exists(json, 'clientId') ? undefined : json['clientId'],
        'clientSecret': !runtime_1.exists(json, 'clientSecret') ? undefined : json['clientSecret'],
        'enabled': json['enabled'],
        'expiration': json['expiration'],
        'machineId': !runtime_1.exists(json, 'machineId') ? undefined : json['machineId'],
        'tokenId': !runtime_1.exists(json, 'tokenId') ? undefined : json['tokenId'],
        'updatedTimestamp': !runtime_1.exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
        'username': !runtime_1.exists(json, 'username') ? undefined : json['username'],
    };
}
exports.TokenInputFromJSONTyped = TokenInputFromJSONTyped;
function TokenInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'authToken': value.authToken,
        'authUrl': value.authUrl,
        'clientId': value.clientId,
        'clientSecret': value.clientSecret,
        'enabled': value.enabled,
        'expiration': value.expiration,
        'machineId': value.machineId,
        'tokenId': value.tokenId,
        'updatedTimestamp': value.updatedTimestamp,
        'username': value.username,
    };
}
exports.TokenInputToJSON = TokenInputToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityProviderInputToJSON = exports.IdentityProviderInputFromJSONTyped = exports.IdentityProviderInputFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function IdentityProviderInputFromJSON(json) {
    return IdentityProviderInputFromJSONTyped(json, false);
}
exports.IdentityProviderInputFromJSON = IdentityProviderInputFromJSON;
function IdentityProviderInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'attributeMapping': !runtime_1.exists(json, 'attributeMapping') ? undefined : json['attributeMapping'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'details': json['details'],
        'enabled': !runtime_1.exists(json, 'enabled') ? undefined : json['enabled'],
        'identifiers': !runtime_1.exists(json, 'identifiers') ? undefined : json['identifiers'],
        'identityProviderId': !runtime_1.exists(json, 'identityProviderId') ? undefined : json['identityProviderId'],
        'name': json['name'],
        'type': _1.IdentityProviderTypeEnumFromJSON(json['type']),
        'updatedTimestamp': !runtime_1.exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
    };
}
exports.IdentityProviderInputFromJSONTyped = IdentityProviderInputFromJSONTyped;
function IdentityProviderInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'attributeMapping': value.attributeMapping,
        'description': value.description,
        'details': value.details,
        'enabled': value.enabled,
        'identifiers': value.identifiers,
        'identityProviderId': value.identityProviderId,
        'name': value.name,
        'type': _1.IdentityProviderTypeEnumToJSON(value.type),
        'updatedTimestamp': value.updatedTimestamp,
    };
}
exports.IdentityProviderInputToJSON = IdentityProviderInputToJSON;

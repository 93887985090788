"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiErrorToJSON = exports.ApiErrorFromJSONTyped = exports.ApiErrorFromJSON = void 0;
var runtime_1 = require("../runtime");
function ApiErrorFromJSON(json) {
    return ApiErrorFromJSONTyped(json, false);
}
exports.ApiErrorFromJSON = ApiErrorFromJSON;
function ApiErrorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cause': !runtime_1.exists(json, 'cause') ? undefined : json['cause'],
        'details': !runtime_1.exists(json, 'details') ? undefined : json['details'],
        'errorId': !runtime_1.exists(json, 'errorId') ? undefined : json['errorId'],
        'message': json['message'],
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
    };
}
exports.ApiErrorFromJSONTyped = ApiErrorFromJSONTyped;
function ApiErrorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cause': value.cause,
        'details': value.details,
        'errorId': value.errorId,
        'message': value.message,
        'name': value.name,
    };
}
exports.ApiErrorToJSON = ApiErrorToJSON;

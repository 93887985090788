"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryRewriteInputQuerySubstitutionsToJSON = exports.QueryRewriteInputQuerySubstitutionsFromJSONTyped = exports.QueryRewriteInputQuerySubstitutionsFromJSON = void 0;
var runtime_1 = require("../runtime");
function QueryRewriteInputQuerySubstitutionsFromJSON(json) {
    return QueryRewriteInputQuerySubstitutionsFromJSONTyped(json, false);
}
exports.QueryRewriteInputQuerySubstitutionsFromJSON = QueryRewriteInputQuerySubstitutionsFromJSON;
function QueryRewriteInputQuerySubstitutionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'query': !runtime_1.exists(json, 'query') ? undefined : json['query'],
    };
}
exports.QueryRewriteInputQuerySubstitutionsFromJSONTyped = QueryRewriteInputQuerySubstitutionsFromJSONTyped;
function QueryRewriteInputQuerySubstitutionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'query': value.query,
    };
}
exports.QueryRewriteInputQuerySubstitutionsToJSON = QueryRewriteInputQuerySubstitutionsToJSON;

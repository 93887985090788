"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataProductTransformToJSON = exports.DataProductTransformFromJSONTyped = exports.DataProductTransformFromJSON = void 0;
var runtime_1 = require("../runtime");
function DataProductTransformFromJSON(json) {
    return DataProductTransformFromJSONTyped(json, false);
}
exports.DataProductTransformFromJSON = DataProductTransformFromJSON;
function DataProductTransformFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'inlineScriptContent': !runtime_1.exists(json, 'inlineScriptContent') ? undefined : json['inlineScriptContent'],
        'inputArgs': !runtime_1.exists(json, 'inputArgs') ? undefined : json['inputArgs'],
        'namespace': json['namespace'],
        'scriptId': json['scriptId'],
    };
}
exports.DataProductTransformFromJSONTyped = DataProductTransformFromJSONTyped;
function DataProductTransformToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'inlineScriptContent': value.inlineScriptContent,
        'inputArgs': value.inputArgs,
        'namespace': value.namespace,
        'scriptId': value.scriptId,
    };
}
exports.DataProductTransformToJSON = DataProductTransformToJSON;

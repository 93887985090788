"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCostOutputGroupsToJSON = exports.GetCostOutputGroupsFromJSONTyped = exports.GetCostOutputGroupsFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GetCostOutputGroupsFromJSON(json) {
    return GetCostOutputGroupsFromJSONTyped(json, false);
}
exports.GetCostOutputGroupsFromJSON = GetCostOutputGroupsFromJSON;
function GetCostOutputGroupsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'keys': !runtime_1.exists(json, 'keys') ? undefined : json['keys'],
        'metrics': !runtime_1.exists(json, 'metrics') ? undefined : (runtime_1.mapValues(json['metrics'], _1.MetricValueFromJSON)),
    };
}
exports.GetCostOutputGroupsFromJSONTyped = GetCostOutputGroupsFromJSONTyped;
function GetCostOutputGroupsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'keys': value.keys,
        'metrics': value.metrics === undefined ? undefined : (runtime_1.mapValues(value.metrics, _1.MetricValueToJSON)),
    };
}
exports.GetCostOutputGroupsToJSON = GetCostOutputGroupsToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostDataProductDomainDataProductFileUploadContentTypeEnum = exports.GetDataProductDomainDataProductFileUploadContentTypeEnum = exports.DefaultApi = void 0;
var runtime = require("../runtime");
var models_1 = require("../models");
/**
 *
 */
var DefaultApi = /** @class */ (function (_super) {
    __extends(DefaultApi, _super);
    function DefaultApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Delete administration budgets
     */
    DefaultApi.prototype.deleteAdministrationBudgetsRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/administration/budgets",
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.BudgetResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete administration budgets
     */
    DefaultApi.prototype.deleteAdministrationBudgets = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteAdministrationBudgetsRaw(initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete administration start tear down destroy data
     */
    DefaultApi.prototype.deleteAdministrationStartTearDownDestroyDataRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/administration/start-tear-down/destroy-data",
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.TearDownDetailsFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete administration start tear down destroy data
     */
    DefaultApi.prototype.deleteAdministrationStartTearDownDestroyData = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteAdministrationStartTearDownDestroyDataRaw(initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete administration start tear down retain data
     */
    DefaultApi.prototype.deleteAdministrationStartTearDownRetainDataRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/administration/start-tear-down/retain-data",
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.TearDownDetailsFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete administration start tear down retain data
     */
    DefaultApi.prototype.deleteAdministrationStartTearDownRetainData = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteAdministrationStartTearDownRetainDataRaw(initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete api access policy
     */
    DefaultApi.prototype.deleteApiAccessPolicyRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.apiAccessPolicyId === null || requestParameters.apiAccessPolicyId === undefined) {
                            throw new runtime.RequiredError('apiAccessPolicyId', 'Required parameter requestParameters.apiAccessPolicyId was null or undefined when calling deleteApiAccessPolicy.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/api-access-policy/{apiAccessPolicyId}".replace("{" + "apiAccessPolicyId" + "}", encodeURIComponent(String(requestParameters.apiAccessPolicyId))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ApiAccessPolicyEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete api access policy
     */
    DefaultApi.prototype.deleteApiAccessPolicy = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteApiAccessPolicyRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete data product domain
     */
    DefaultApi.prototype.deleteDataProductDomainRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling deleteDataProductDomain.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/data-product/domain/{domainId}".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DomainEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete data product domain
     */
    DefaultApi.prototype.deleteDataProductDomain = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteDataProductDomainRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete data product domain data product
     */
    DefaultApi.prototype.deleteDataProductDomainDataProductRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling deleteDataProductDomainDataProduct.');
                        }
                        if (requestParameters.dataProductId === null || requestParameters.dataProductId === undefined) {
                            throw new runtime.RequiredError('dataProductId', 'Required parameter requestParameters.dataProductId was null or undefined when calling deleteDataProductDomainDataProduct.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/data-product/domain/{domainId}/data-product/{dataProductId}".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))).replace("{" + "dataProductId" + "}", encodeURIComponent(String(requestParameters.dataProductId))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DataProductEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete data product domain data product
     */
    DefaultApi.prototype.deleteDataProductDomainDataProduct = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteDataProductDomainDataProductRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete data product scripts namespace script
     */
    DefaultApi.prototype.deleteDataProductScriptsNamespaceScriptRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.namespace === null || requestParameters.namespace === undefined) {
                            throw new runtime.RequiredError('namespace', 'Required parameter requestParameters.namespace was null or undefined when calling deleteDataProductScriptsNamespaceScript.');
                        }
                        if (requestParameters.scriptId === null || requestParameters.scriptId === undefined) {
                            throw new runtime.RequiredError('scriptId', 'Required parameter requestParameters.scriptId was null or undefined when calling deleteDataProductScriptsNamespaceScript.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/data-product/scripts/namespace/{namespace}/script/{scriptId}".replace("{" + "namespace" + "}", encodeURIComponent(String(requestParameters.namespace))).replace("{" + "scriptId" + "}", encodeURIComponent(String(requestParameters.scriptId))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ScriptEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete data product scripts namespace script
     */
    DefaultApi.prototype.deleteDataProductScriptsNamespaceScript = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteDataProductScriptsNamespaceScriptRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete governance policy attribute values
     */
    DefaultApi.prototype.deleteGovernancePolicyAttributeValuesRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.deleteGovernancePolicyAttributeValuesRequest === null || requestParameters.deleteGovernancePolicyAttributeValuesRequest === undefined) {
                            throw new runtime.RequiredError('deleteGovernancePolicyAttributeValuesRequest', 'Required parameter requestParameters.deleteGovernancePolicyAttributeValuesRequest was null or undefined when calling deleteGovernancePolicyAttributeValues.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/attribute-values",
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.DeleteGovernancePolicyAttributeValuesRequestToJSON(requestParameters.deleteGovernancePolicyAttributeValuesRequest),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DeleteGovernancePolicyAttributeValuesResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete governance policy attribute values
     */
    DefaultApi.prototype.deleteGovernancePolicyAttributeValues = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteGovernancePolicyAttributeValuesRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete governance policy attribute values group
     */
    DefaultApi.prototype.deleteGovernancePolicyAttributeValuesGroupRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.ontologyNamespace === null || requestParameters.ontologyNamespace === undefined) {
                            throw new runtime.RequiredError('ontologyNamespace', 'Required parameter requestParameters.ontologyNamespace was null or undefined when calling deleteGovernancePolicyAttributeValuesGroup.');
                        }
                        if (requestParameters.attributeId === null || requestParameters.attributeId === undefined) {
                            throw new runtime.RequiredError('attributeId', 'Required parameter requestParameters.attributeId was null or undefined when calling deleteGovernancePolicyAttributeValuesGroup.');
                        }
                        if (requestParameters.group === null || requestParameters.group === undefined) {
                            throw new runtime.RequiredError('group', 'Required parameter requestParameters.group was null or undefined when calling deleteGovernancePolicyAttributeValuesGroup.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/attribute-values/{ontologyNamespace}/{attributeId}/group/{group}".replace("{" + "ontologyNamespace" + "}", encodeURIComponent(String(requestParameters.ontologyNamespace))).replace("{" + "attributeId" + "}", encodeURIComponent(String(requestParameters.attributeId))).replace("{" + "group" + "}", encodeURIComponent(String(requestParameters.group))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.AttributeValuePolicyEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete governance policy attribute values group
     */
    DefaultApi.prototype.deleteGovernancePolicyAttributeValuesGroup = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteGovernancePolicyAttributeValuesGroupRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete governance policy attributes
     */
    DefaultApi.prototype.deleteGovernancePolicyAttributesRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.deleteGovernancePolicyAttributesRequest === null || requestParameters.deleteGovernancePolicyAttributesRequest === undefined) {
                            throw new runtime.RequiredError('deleteGovernancePolicyAttributesRequest', 'Required parameter requestParameters.deleteGovernancePolicyAttributesRequest was null or undefined when calling deleteGovernancePolicyAttributes.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/attributes",
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.DeleteGovernancePolicyAttributesRequestToJSON(requestParameters.deleteGovernancePolicyAttributesRequest),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DeleteGovernancePolicyAttributesResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete governance policy attributes
     */
    DefaultApi.prototype.deleteGovernancePolicyAttributes = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteGovernancePolicyAttributesRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete governance policy attributes group
     */
    DefaultApi.prototype.deleteGovernancePolicyAttributesGroupRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.ontologyNamespace === null || requestParameters.ontologyNamespace === undefined) {
                            throw new runtime.RequiredError('ontologyNamespace', 'Required parameter requestParameters.ontologyNamespace was null or undefined when calling deleteGovernancePolicyAttributesGroup.');
                        }
                        if (requestParameters.attributeId === null || requestParameters.attributeId === undefined) {
                            throw new runtime.RequiredError('attributeId', 'Required parameter requestParameters.attributeId was null or undefined when calling deleteGovernancePolicyAttributesGroup.');
                        }
                        if (requestParameters.group === null || requestParameters.group === undefined) {
                            throw new runtime.RequiredError('group', 'Required parameter requestParameters.group was null or undefined when calling deleteGovernancePolicyAttributesGroup.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/attributes/{ontologyNamespace}/{attributeId}/group/{group}".replace("{" + "ontologyNamespace" + "}", encodeURIComponent(String(requestParameters.ontologyNamespace))).replace("{" + "attributeId" + "}", encodeURIComponent(String(requestParameters.attributeId))).replace("{" + "group" + "}", encodeURIComponent(String(requestParameters.group))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.AttributePolicyEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete governance policy attributes group
     */
    DefaultApi.prototype.deleteGovernancePolicyAttributesGroup = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteGovernancePolicyAttributesGroupRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete governance policy default lens domain data product
     */
    DefaultApi.prototype.deleteGovernancePolicyDefaultLensDomainDataProductRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling deleteGovernancePolicyDefaultLensDomainDataProduct.');
                        }
                        if (requestParameters.dataProductId === null || requestParameters.dataProductId === undefined) {
                            throw new runtime.RequiredError('dataProductId', 'Required parameter requestParameters.dataProductId was null or undefined when calling deleteGovernancePolicyDefaultLensDomainDataProduct.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/default-lens/domain/{domainId}/data-product/{dataProductId}".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))).replace("{" + "dataProductId" + "}", encodeURIComponent(String(requestParameters.dataProductId))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DefaultLensPolicyEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete governance policy default lens domain data product
     */
    DefaultApi.prototype.deleteGovernancePolicyDefaultLensDomainDataProduct = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteGovernancePolicyDefaultLensDomainDataProductRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete governance policy domain data product
     */
    DefaultApi.prototype.deleteGovernancePolicyDomainDataProductRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling deleteGovernancePolicyDomainDataProduct.');
                        }
                        if (requestParameters.dataProductId === null || requestParameters.dataProductId === undefined) {
                            throw new runtime.RequiredError('dataProductId', 'Required parameter requestParameters.dataProductId was null or undefined when calling deleteGovernancePolicyDomainDataProduct.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/domain/{domainId}/data-product/{dataProductId}".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))).replace("{" + "dataProductId" + "}", encodeURIComponent(String(requestParameters.dataProductId))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DataProductPolicyEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete governance policy domain data product
     */
    DefaultApi.prototype.deleteGovernancePolicyDomainDataProduct = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteGovernancePolicyDomainDataProductRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete identity group
     */
    DefaultApi.prototype.deleteIdentityGroupRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
                            throw new runtime.RequiredError('groupId', 'Required parameter requestParameters.groupId was null or undefined when calling deleteIdentityGroup.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/identity/group/{groupId}".replace("{" + "groupId" + "}", encodeURIComponent(String(requestParameters.groupId))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.GroupEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete identity group
     */
    DefaultApi.prototype.deleteIdentityGroup = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteIdentityGroupRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete identity machine
     */
    DefaultApi.prototype.deleteIdentityMachineRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
                            throw new runtime.RequiredError('machineId', 'Required parameter requestParameters.machineId was null or undefined when calling deleteIdentityMachine.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/identity/machine/{machineId}".replace("{" + "machineId" + "}", encodeURIComponent(String(requestParameters.machineId))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.MachineEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete identity machine
     */
    DefaultApi.prototype.deleteIdentityMachine = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteIdentityMachineRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete identity machine token
     */
    DefaultApi.prototype.deleteIdentityMachineTokenRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
                            throw new runtime.RequiredError('machineId', 'Required parameter requestParameters.machineId was null or undefined when calling deleteIdentityMachineToken.');
                        }
                        if (requestParameters.tokenId === null || requestParameters.tokenId === undefined) {
                            throw new runtime.RequiredError('tokenId', 'Required parameter requestParameters.tokenId was null or undefined when calling deleteIdentityMachineToken.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/identity/machine/{machineId}/token/{tokenId}".replace("{" + "machineId" + "}", encodeURIComponent(String(requestParameters.machineId))).replace("{" + "tokenId" + "}", encodeURIComponent(String(requestParameters.tokenId))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.TokenEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete identity machine token
     */
    DefaultApi.prototype.deleteIdentityMachineToken = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteIdentityMachineTokenRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete identity provider
     */
    DefaultApi.prototype.deleteIdentityProviderRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.identityProviderId === null || requestParameters.identityProviderId === undefined) {
                            throw new runtime.RequiredError('identityProviderId', 'Required parameter requestParameters.identityProviderId was null or undefined when calling deleteIdentityProvider.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/identity/provider/{identityProviderId}".replace("{" + "identityProviderId" + "}", encodeURIComponent(String(requestParameters.identityProviderId))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.IdentityProviderEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete identity provider
     */
    DefaultApi.prototype.deleteIdentityProvider = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteIdentityProviderRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete identity request
     */
    DefaultApi.prototype.deleteIdentityRequestRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
                            throw new runtime.RequiredError('groupId', 'Required parameter requestParameters.groupId was null or undefined when calling deleteIdentityRequest.');
                        }
                        if (requestParameters.userId === null || requestParameters.userId === undefined) {
                            throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling deleteIdentityRequest.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/identity/request/{groupId}/{userId}".replace("{" + "groupId" + "}", encodeURIComponent(String(requestParameters.groupId))).replace("{" + "userId" + "}", encodeURIComponent(String(requestParameters.userId))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.AccessRequestEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete identity request
     */
    DefaultApi.prototype.deleteIdentityRequest = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteIdentityRequestRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete ontology
     */
    DefaultApi.prototype.deleteOntologyRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.ontologyNamespace === null || requestParameters.ontologyNamespace === undefined) {
                            throw new runtime.RequiredError('ontologyNamespace', 'Required parameter requestParameters.ontologyNamespace was null or undefined when calling deleteOntology.');
                        }
                        if (requestParameters.ontologyId === null || requestParameters.ontologyId === undefined) {
                            throw new runtime.RequiredError('ontologyId', 'Required parameter requestParameters.ontologyId was null or undefined when calling deleteOntology.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/ontology/{ontologyNamespace}/{ontologyId}".replace("{" + "ontologyNamespace" + "}", encodeURIComponent(String(requestParameters.ontologyNamespace))).replace("{" + "ontologyId" + "}", encodeURIComponent(String(requestParameters.ontologyId))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.OntologyEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete ontology
     */
    DefaultApi.prototype.deleteOntology = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteOntologyRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete query saved query
     */
    DefaultApi.prototype.deleteQuerySavedQueryRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.namespace === null || requestParameters.namespace === undefined) {
                            throw new runtime.RequiredError('namespace', 'Required parameter requestParameters.namespace was null or undefined when calling deleteQuerySavedQuery.');
                        }
                        if (requestParameters.queryId === null || requestParameters.queryId === undefined) {
                            throw new runtime.RequiredError('queryId', 'Required parameter requestParameters.queryId was null or undefined when calling deleteQuerySavedQuery.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/query/saved-query/{namespace}/{queryId}".replace("{" + "namespace" + "}", encodeURIComponent(String(requestParameters.namespace))).replace("{" + "queryId" + "}", encodeURIComponent(String(requestParameters.queryId))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.SavedQueryEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Delete query saved query
     */
    DefaultApi.prototype.deleteQuerySavedQuery = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteQuerySavedQueryRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get administration budgets
     */
    DefaultApi.prototype.getAdministrationBudgetsRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/administration/budgets",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.BudgetDetailsFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get administration budgets
     */
    DefaultApi.prototype.getAdministrationBudgets = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAdministrationBudgetsRaw(initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get api access policy
     */
    DefaultApi.prototype.getApiAccessPolicyRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.apiAccessPolicyId === null || requestParameters.apiAccessPolicyId === undefined) {
                            throw new runtime.RequiredError('apiAccessPolicyId', 'Required parameter requestParameters.apiAccessPolicyId was null or undefined when calling getApiAccessPolicy.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/api-access-policy/{apiAccessPolicyId}".replace("{" + "apiAccessPolicyId" + "}", encodeURIComponent(String(requestParameters.apiAccessPolicyId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ApiAccessPolicyEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get api access policy
     */
    DefaultApi.prototype.getApiAccessPolicy = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getApiAccessPolicyRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get data product domain
     */
    DefaultApi.prototype.getDataProductDomainRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling getDataProductDomain.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/data-product/domain/{domainId}".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DomainEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get data product domain
     */
    DefaultApi.prototype.getDataProductDomain = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDataProductDomainRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get data product domain data product
     */
    DefaultApi.prototype.getDataProductDomainDataProductRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling getDataProductDomainDataProduct.');
                        }
                        if (requestParameters.dataProductId === null || requestParameters.dataProductId === undefined) {
                            throw new runtime.RequiredError('dataProductId', 'Required parameter requestParameters.dataProductId was null or undefined when calling getDataProductDomainDataProduct.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/data-product/domain/{domainId}/data-product/{dataProductId}".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))).replace("{" + "dataProductId" + "}", encodeURIComponent(String(requestParameters.dataProductId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.GovernedDataProductEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get data product domain data product
     */
    DefaultApi.prototype.getDataProductDomainDataProduct = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDataProductDomainDataProductRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get data product domain data product file upload
     */
    DefaultApi.prototype.getDataProductDomainDataProductFileUploadRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.contentType === null || requestParameters.contentType === undefined) {
                            throw new runtime.RequiredError('contentType', 'Required parameter requestParameters.contentType was null or undefined when calling getDataProductDomainDataProductFileUpload.');
                        }
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling getDataProductDomainDataProductFileUpload.');
                        }
                        if (requestParameters.dataProductId === null || requestParameters.dataProductId === undefined) {
                            throw new runtime.RequiredError('dataProductId', 'Required parameter requestParameters.dataProductId was null or undefined when calling getDataProductDomainDataProductFileUpload.');
                        }
                        if (requestParameters.fileName === null || requestParameters.fileName === undefined) {
                            throw new runtime.RequiredError('fileName', 'Required parameter requestParameters.fileName was null or undefined when calling getDataProductDomainDataProductFileUpload.');
                        }
                        queryParameters = {};
                        if (requestParameters.contentType !== undefined) {
                            queryParameters['contentType'] = requestParameters.contentType;
                        }
                        if (requestParameters.uploadId !== undefined) {
                            queryParameters['uploadId'] = requestParameters.uploadId;
                        }
                        if (requestParameters.partNumber !== undefined) {
                            queryParameters['partNumber'] = requestParameters.partNumber;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/data-product/domain/{domainId}/data-product/{dataProductId}/file/upload/{fileName}".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))).replace("{" + "dataProductId" + "}", encodeURIComponent(String(requestParameters.dataProductId))).replace("{" + "fileName" + "}", encodeURIComponent(String(requestParameters.fileName))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DataProductFileUploadFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get data product domain data product file upload
     */
    DefaultApi.prototype.getDataProductDomainDataProductFileUpload = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDataProductDomainDataProductFileUploadRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get data product dynamo db table stream
     */
    DefaultApi.prototype.getDataProductDynamoDBTableStreamRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.tableArn === null || requestParameters.tableArn === undefined) {
                            throw new runtime.RequiredError('tableArn', 'Required parameter requestParameters.tableArn was null or undefined when calling getDataProductDynamoDBTableStream.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/data-product/dynamoDB/table/{tableArn}/stream".replace("{" + "tableArn" + "}", encodeURIComponent(String(requestParameters.tableArn))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.TableStreamEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get data product dynamo db table stream
     */
    DefaultApi.prototype.getDataProductDynamoDBTableStream = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDataProductDynamoDBTableStreamRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get data product preview domain data product
     */
    DefaultApi.prototype.getDataProductPreviewDomainDataProductRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling getDataProductPreviewDomainDataProduct.');
                        }
                        if (requestParameters.dataProductId === null || requestParameters.dataProductId === undefined) {
                            throw new runtime.RequiredError('dataProductId', 'Required parameter requestParameters.dataProductId was null or undefined when calling getDataProductPreviewDomainDataProduct.');
                        }
                        if (requestParameters.previewId === null || requestParameters.previewId === undefined) {
                            throw new runtime.RequiredError('previewId', 'Required parameter requestParameters.previewId was null or undefined when calling getDataProductPreviewDomainDataProduct.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/data-product-preview/domain/{domainId}/data-product/{dataProductId}/{previewId}".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))).replace("{" + "dataProductId" + "}", encodeURIComponent(String(requestParameters.dataProductId))).replace("{" + "previewId" + "}", encodeURIComponent(String(requestParameters.previewId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DataProductPreviewFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get data product preview domain data product
     */
    DefaultApi.prototype.getDataProductPreviewDomainDataProduct = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDataProductPreviewDomainDataProductRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get data product scripts namespace script
     */
    DefaultApi.prototype.getDataProductScriptsNamespaceScriptRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.namespace === null || requestParameters.namespace === undefined) {
                            throw new runtime.RequiredError('namespace', 'Required parameter requestParameters.namespace was null or undefined when calling getDataProductScriptsNamespaceScript.');
                        }
                        if (requestParameters.scriptId === null || requestParameters.scriptId === undefined) {
                            throw new runtime.RequiredError('scriptId', 'Required parameter requestParameters.scriptId was null or undefined when calling getDataProductScriptsNamespaceScript.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/data-product/scripts/namespace/{namespace}/script/{scriptId}".replace("{" + "namespace" + "}", encodeURIComponent(String(requestParameters.namespace))).replace("{" + "scriptId" + "}", encodeURIComponent(String(requestParameters.scriptId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ScriptEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get data product scripts namespace script
     */
    DefaultApi.prototype.getDataProductScriptsNamespaceScript = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDataProductScriptsNamespaceScriptRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get governance policy attribute values
     */
    DefaultApi.prototype.getGovernancePolicyAttributeValuesRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.namespaceAndAttributeIds === null || requestParameters.namespaceAndAttributeIds === undefined) {
                            throw new runtime.RequiredError('namespaceAndAttributeIds', 'Required parameter requestParameters.namespaceAndAttributeIds was null or undefined when calling getGovernancePolicyAttributeValues.');
                        }
                        if (requestParameters.group === null || requestParameters.group === undefined) {
                            throw new runtime.RequiredError('group', 'Required parameter requestParameters.group was null or undefined when calling getGovernancePolicyAttributeValues.');
                        }
                        queryParameters = {};
                        if (requestParameters.namespaceAndAttributeIds) {
                            queryParameters['namespaceAndAttributeIds'] = requestParameters.namespaceAndAttributeIds;
                        }
                        if (requestParameters.group !== undefined) {
                            queryParameters['group'] = requestParameters.group;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/attribute-values",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.GetGovernancePolicyAttributeValuesResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get governance policy attribute values
     */
    DefaultApi.prototype.getGovernancePolicyAttributeValues = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getGovernancePolicyAttributeValuesRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get governance policy attribute values attribute
     */
    DefaultApi.prototype.getGovernancePolicyAttributeValuesAttributeRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.ontologyNamespace === null || requestParameters.ontologyNamespace === undefined) {
                            throw new runtime.RequiredError('ontologyNamespace', 'Required parameter requestParameters.ontologyNamespace was null or undefined when calling getGovernancePolicyAttributeValuesAttribute.');
                        }
                        if (requestParameters.attributeId === null || requestParameters.attributeId === undefined) {
                            throw new runtime.RequiredError('attributeId', 'Required parameter requestParameters.attributeId was null or undefined when calling getGovernancePolicyAttributeValuesAttribute.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/attribute-values/{ontologyNamespace}/{attributeId}/attribute".replace("{" + "ontologyNamespace" + "}", encodeURIComponent(String(requestParameters.ontologyNamespace))).replace("{" + "attributeId" + "}", encodeURIComponent(String(requestParameters.attributeId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.GetGovernancePolicyAttributeValuesAttributeResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get governance policy attribute values attribute
     */
    DefaultApi.prototype.getGovernancePolicyAttributeValuesAttribute = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getGovernancePolicyAttributeValuesAttributeRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get governance policy attribute values group
     */
    DefaultApi.prototype.getGovernancePolicyAttributeValuesGroupRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.ontologyNamespace === null || requestParameters.ontologyNamespace === undefined) {
                            throw new runtime.RequiredError('ontologyNamespace', 'Required parameter requestParameters.ontologyNamespace was null or undefined when calling getGovernancePolicyAttributeValuesGroup.');
                        }
                        if (requestParameters.attributeId === null || requestParameters.attributeId === undefined) {
                            throw new runtime.RequiredError('attributeId', 'Required parameter requestParameters.attributeId was null or undefined when calling getGovernancePolicyAttributeValuesGroup.');
                        }
                        if (requestParameters.group === null || requestParameters.group === undefined) {
                            throw new runtime.RequiredError('group', 'Required parameter requestParameters.group was null or undefined when calling getGovernancePolicyAttributeValuesGroup.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/attribute-values/{ontologyNamespace}/{attributeId}/group/{group}".replace("{" + "ontologyNamespace" + "}", encodeURIComponent(String(requestParameters.ontologyNamespace))).replace("{" + "attributeId" + "}", encodeURIComponent(String(requestParameters.attributeId))).replace("{" + "group" + "}", encodeURIComponent(String(requestParameters.group))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.AttributeValuePolicyEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get governance policy attribute values group
     */
    DefaultApi.prototype.getGovernancePolicyAttributeValuesGroup = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getGovernancePolicyAttributeValuesGroupRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get governance policy attributes
     */
    DefaultApi.prototype.getGovernancePolicyAttributesRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.namespaceAndAttributeIds === null || requestParameters.namespaceAndAttributeIds === undefined) {
                            throw new runtime.RequiredError('namespaceAndAttributeIds', 'Required parameter requestParameters.namespaceAndAttributeIds was null or undefined when calling getGovernancePolicyAttributes.');
                        }
                        if (requestParameters.group === null || requestParameters.group === undefined) {
                            throw new runtime.RequiredError('group', 'Required parameter requestParameters.group was null or undefined when calling getGovernancePolicyAttributes.');
                        }
                        queryParameters = {};
                        if (requestParameters.namespaceAndAttributeIds) {
                            queryParameters['namespaceAndAttributeIds'] = requestParameters.namespaceAndAttributeIds;
                        }
                        if (requestParameters.group !== undefined) {
                            queryParameters['group'] = requestParameters.group;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/attributes",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.GetGovernancePolicyAttributesResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get governance policy attributes
     */
    DefaultApi.prototype.getGovernancePolicyAttributes = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getGovernancePolicyAttributesRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get governance policy attributes attribute
     */
    DefaultApi.prototype.getGovernancePolicyAttributesAttributeRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.ontologyNamespace === null || requestParameters.ontologyNamespace === undefined) {
                            throw new runtime.RequiredError('ontologyNamespace', 'Required parameter requestParameters.ontologyNamespace was null or undefined when calling getGovernancePolicyAttributesAttribute.');
                        }
                        if (requestParameters.attributeId === null || requestParameters.attributeId === undefined) {
                            throw new runtime.RequiredError('attributeId', 'Required parameter requestParameters.attributeId was null or undefined when calling getGovernancePolicyAttributesAttribute.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/attributes/{ontologyNamespace}/{attributeId}/attribute".replace("{" + "ontologyNamespace" + "}", encodeURIComponent(String(requestParameters.ontologyNamespace))).replace("{" + "attributeId" + "}", encodeURIComponent(String(requestParameters.attributeId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.GetGovernancePolicyAttributesAttributeResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get governance policy attributes attribute
     */
    DefaultApi.prototype.getGovernancePolicyAttributesAttribute = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getGovernancePolicyAttributesAttributeRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get governance policy attributes group
     */
    DefaultApi.prototype.getGovernancePolicyAttributesGroupRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.ontologyNamespace === null || requestParameters.ontologyNamespace === undefined) {
                            throw new runtime.RequiredError('ontologyNamespace', 'Required parameter requestParameters.ontologyNamespace was null or undefined when calling getGovernancePolicyAttributesGroup.');
                        }
                        if (requestParameters.attributeId === null || requestParameters.attributeId === undefined) {
                            throw new runtime.RequiredError('attributeId', 'Required parameter requestParameters.attributeId was null or undefined when calling getGovernancePolicyAttributesGroup.');
                        }
                        if (requestParameters.group === null || requestParameters.group === undefined) {
                            throw new runtime.RequiredError('group', 'Required parameter requestParameters.group was null or undefined when calling getGovernancePolicyAttributesGroup.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/attributes/{ontologyNamespace}/{attributeId}/group/{group}".replace("{" + "ontologyNamespace" + "}", encodeURIComponent(String(requestParameters.ontologyNamespace))).replace("{" + "attributeId" + "}", encodeURIComponent(String(requestParameters.attributeId))).replace("{" + "group" + "}", encodeURIComponent(String(requestParameters.group))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.AttributePolicyEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get governance policy attributes group
     */
    DefaultApi.prototype.getGovernancePolicyAttributesGroup = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getGovernancePolicyAttributesGroupRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get governance policy default lens domain data product
     */
    DefaultApi.prototype.getGovernancePolicyDefaultLensDomainDataProductRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling getGovernancePolicyDefaultLensDomainDataProduct.');
                        }
                        if (requestParameters.dataProductId === null || requestParameters.dataProductId === undefined) {
                            throw new runtime.RequiredError('dataProductId', 'Required parameter requestParameters.dataProductId was null or undefined when calling getGovernancePolicyDefaultLensDomainDataProduct.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/default-lens/domain/{domainId}/data-product/{dataProductId}".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))).replace("{" + "dataProductId" + "}", encodeURIComponent(String(requestParameters.dataProductId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DefaultLensPolicyEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get governance policy default lens domain data product
     */
    DefaultApi.prototype.getGovernancePolicyDefaultLensDomainDataProduct = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getGovernancePolicyDefaultLensDomainDataProductRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get governance policy domain data product
     */
    DefaultApi.prototype.getGovernancePolicyDomainDataProductRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling getGovernancePolicyDomainDataProduct.');
                        }
                        if (requestParameters.dataProductId === null || requestParameters.dataProductId === undefined) {
                            throw new runtime.RequiredError('dataProductId', 'Required parameter requestParameters.dataProductId was null or undefined when calling getGovernancePolicyDomainDataProduct.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/domain/{domainId}/data-product/{dataProductId}".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))).replace("{" + "dataProductId" + "}", encodeURIComponent(String(requestParameters.dataProductId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DataProductPolicyEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get governance policy domain data product
     */
    DefaultApi.prototype.getGovernancePolicyDomainDataProduct = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getGovernancePolicyDomainDataProductRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get governance policy domain data product permissions
     */
    DefaultApi.prototype.getGovernancePolicyDomainDataProductPermissionsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.groups === null || requestParameters.groups === undefined) {
                            throw new runtime.RequiredError('groups', 'Required parameter requestParameters.groups was null or undefined when calling getGovernancePolicyDomainDataProductPermissions.');
                        }
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling getGovernancePolicyDomainDataProductPermissions.');
                        }
                        if (requestParameters.dataProductId === null || requestParameters.dataProductId === undefined) {
                            throw new runtime.RequiredError('dataProductId', 'Required parameter requestParameters.dataProductId was null or undefined when calling getGovernancePolicyDomainDataProductPermissions.');
                        }
                        queryParameters = {};
                        if (requestParameters.groups) {
                            queryParameters['groups'] = requestParameters.groups;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/domain/{domainId}/data-product/{dataProductId}/permissions".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))).replace("{" + "dataProductId" + "}", encodeURIComponent(String(requestParameters.dataProductId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DataProductPermissionsEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get governance policy domain data product permissions
     */
    DefaultApi.prototype.getGovernancePolicyDomainDataProductPermissions = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getGovernancePolicyDomainDataProductPermissionsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get identity attributes
     */
    DefaultApi.prototype.getIdentityAttributesRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/identity/attributes",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.IdentityAttributesFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get identity attributes
     */
    DefaultApi.prototype.getIdentityAttributes = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getIdentityAttributesRaw(initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get identity group
     */
    DefaultApi.prototype.getIdentityGroupRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
                            throw new runtime.RequiredError('groupId', 'Required parameter requestParameters.groupId was null or undefined when calling getIdentityGroup.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/identity/group/{groupId}".replace("{" + "groupId" + "}", encodeURIComponent(String(requestParameters.groupId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.GroupEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get identity group
     */
    DefaultApi.prototype.getIdentityGroup = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getIdentityGroupRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get identity machine
     */
    DefaultApi.prototype.getIdentityMachineRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
                            throw new runtime.RequiredError('machineId', 'Required parameter requestParameters.machineId was null or undefined when calling getIdentityMachine.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/identity/machine/{machineId}".replace("{" + "machineId" + "}", encodeURIComponent(String(requestParameters.machineId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.MachineEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get identity machine
     */
    DefaultApi.prototype.getIdentityMachine = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getIdentityMachineRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get identity machine token
     */
    DefaultApi.prototype.getIdentityMachineTokenRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
                            throw new runtime.RequiredError('machineId', 'Required parameter requestParameters.machineId was null or undefined when calling getIdentityMachineToken.');
                        }
                        if (requestParameters.tokenId === null || requestParameters.tokenId === undefined) {
                            throw new runtime.RequiredError('tokenId', 'Required parameter requestParameters.tokenId was null or undefined when calling getIdentityMachineToken.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/identity/machine/{machineId}/token/{tokenId}".replace("{" + "machineId" + "}", encodeURIComponent(String(requestParameters.machineId))).replace("{" + "tokenId" + "}", encodeURIComponent(String(requestParameters.tokenId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.TokenEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get identity machine token
     */
    DefaultApi.prototype.getIdentityMachineToken = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getIdentityMachineTokenRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get identity provider
     */
    DefaultApi.prototype.getIdentityProviderRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.identityProviderId === null || requestParameters.identityProviderId === undefined) {
                            throw new runtime.RequiredError('identityProviderId', 'Required parameter requestParameters.identityProviderId was null or undefined when calling getIdentityProvider.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/identity/provider/{identityProviderId}".replace("{" + "identityProviderId" + "}", encodeURIComponent(String(requestParameters.identityProviderId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.IdentityProviderEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get identity provider
     */
    DefaultApi.prototype.getIdentityProvider = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getIdentityProviderRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get identity request
     */
    DefaultApi.prototype.getIdentityRequestRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
                            throw new runtime.RequiredError('groupId', 'Required parameter requestParameters.groupId was null or undefined when calling getIdentityRequest.');
                        }
                        if (requestParameters.userId === null || requestParameters.userId === undefined) {
                            throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling getIdentityRequest.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/identity/request/{groupId}/{userId}".replace("{" + "groupId" + "}", encodeURIComponent(String(requestParameters.groupId))).replace("{" + "userId" + "}", encodeURIComponent(String(requestParameters.userId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.AccessRequestEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get identity request
     */
    DefaultApi.prototype.getIdentityRequest = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getIdentityRequestRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get ontology
     */
    DefaultApi.prototype.getOntologyRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.ontologyNamespace === null || requestParameters.ontologyNamespace === undefined) {
                            throw new runtime.RequiredError('ontologyNamespace', 'Required parameter requestParameters.ontologyNamespace was null or undefined when calling getOntology.');
                        }
                        if (requestParameters.ontologyId === null || requestParameters.ontologyId === undefined) {
                            throw new runtime.RequiredError('ontologyId', 'Required parameter requestParameters.ontologyId was null or undefined when calling getOntology.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/ontology/{ontologyNamespace}/{ontologyId}".replace("{" + "ontologyNamespace" + "}", encodeURIComponent(String(requestParameters.ontologyNamespace))).replace("{" + "ontologyId" + "}", encodeURIComponent(String(requestParameters.ontologyId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.OntologyEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get ontology
     */
    DefaultApi.prototype.getOntology = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getOntologyRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get permission user
     */
    DefaultApi.prototype.getPermissionUserRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.userId === null || requestParameters.userId === undefined) {
                            throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling getPermissionUser.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/permission/user/{userId}".replace("{" + "userId" + "}", encodeURIComponent(String(requestParameters.userId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.GetPermissionUserResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get permission user
     */
    DefaultApi.prototype.getPermissionUser = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getPermissionUserRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get query result download
     */
    DefaultApi.prototype.getQueryResultDownloadRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.executionId === null || requestParameters.executionId === undefined) {
                            throw new runtime.RequiredError('executionId', 'Required parameter requestParameters.executionId was null or undefined when calling getQueryResultDownload.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/query/{executionId}/result/download".replace("{" + "executionId" + "}", encodeURIComponent(String(requestParameters.executionId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.QueryDownloadFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get query result download
     */
    DefaultApi.prototype.getQueryResultDownload = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getQueryResultDownloadRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get query saved query
     */
    DefaultApi.prototype.getQuerySavedQueryRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.namespace === null || requestParameters.namespace === undefined) {
                            throw new runtime.RequiredError('namespace', 'Required parameter requestParameters.namespace was null or undefined when calling getQuerySavedQuery.');
                        }
                        if (requestParameters.queryId === null || requestParameters.queryId === undefined) {
                            throw new runtime.RequiredError('queryId', 'Required parameter requestParameters.queryId was null or undefined when calling getQuerySavedQuery.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/query/saved-query/{namespace}/{queryId}".replace("{" + "namespace" + "}", encodeURIComponent(String(requestParameters.namespace))).replace("{" + "queryId" + "}", encodeURIComponent(String(requestParameters.queryId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.SavedQueryEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get query saved query
     */
    DefaultApi.prototype.getQuerySavedQuery = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getQuerySavedQueryRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get query schema data product
     */
    DefaultApi.prototype.getQuerySchemaDataProductRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling getQuerySchemaDataProduct.');
                        }
                        if (requestParameters.dataProductId === null || requestParameters.dataProductId === undefined) {
                            throw new runtime.RequiredError('dataProductId', 'Required parameter requestParameters.dataProductId was null or undefined when calling getQuerySchemaDataProduct.');
                        }
                        queryParameters = {};
                        if (requestParameters.dataSetId !== undefined) {
                            queryParameters['dataSetId'] = requestParameters.dataSetId;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/query/schema/{domainId}/data-product/{dataProductId}".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))).replace("{" + "dataProductId" + "}", encodeURIComponent(String(requestParameters.dataProductId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.QuerySchemaFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get query schema data product
     */
    DefaultApi.prototype.getQuerySchemaDataProduct = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getQuerySchemaDataProductRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get query status
     */
    DefaultApi.prototype.getQueryStatusRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.executionId === null || requestParameters.executionId === undefined) {
                            throw new runtime.RequiredError('executionId', 'Required parameter requestParameters.executionId was null or undefined when calling getQueryStatus.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/query/{executionId}/status".replace("{" + "executionId" + "}", encodeURIComponent(String(requestParameters.executionId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.QueryStatusFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get query status
     */
    DefaultApi.prototype.getQueryStatus = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getQueryStatusRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get query sync
     */
    DefaultApi.prototype.getQuerySyncRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.query === null || requestParameters.query === undefined) {
                            throw new runtime.RequiredError('query', 'Required parameter requestParameters.query was null or undefined when calling getQuerySync.');
                        }
                        queryParameters = {};
                        if (requestParameters.query !== undefined) {
                            queryParameters['query'] = requestParameters.query;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/query/sync",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Get query sync
     */
    DefaultApi.prototype.getQuerySync = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getQuerySyncRaw(requestParameters, initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Get query sync result
     */
    DefaultApi.prototype.getQuerySyncResultRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.executionId === null || requestParameters.executionId === undefined) {
                            throw new runtime.RequiredError('executionId', 'Required parameter requestParameters.executionId was null or undefined when calling getQuerySyncResult.');
                        }
                        queryParameters = {};
                        if (requestParameters.nextToken !== undefined) {
                            queryParameters['nextToken'] = requestParameters.nextToken;
                        }
                        if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        if (requestParameters.retrieveDataIntegrity !== undefined) {
                            queryParameters['retrieveDataIntegrity'] = requestParameters.retrieveDataIntegrity;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/query/sync/{executionId}/result".replace("{" + "executionId" + "}", encodeURIComponent(String(requestParameters.executionId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.QueryResultFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get query sync result
     */
    DefaultApi.prototype.getQuerySyncResult = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getQuerySyncResultRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List api access policies
     */
    DefaultApi.prototype.listApiAccessPoliciesRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.nextToken !== undefined) {
                            queryParameters['nextToken'] = requestParameters.nextToken;
                        }
                        if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/api-access-policy",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ListApiAccessPoliciesResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * List api access policies
     */
    DefaultApi.prototype.listApiAccessPolicies = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listApiAccessPoliciesRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List costs
     */
    DefaultApi.prototype.listCostsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.startTimestamp === null || requestParameters.startTimestamp === undefined) {
                            throw new runtime.RequiredError('startTimestamp', 'Required parameter requestParameters.startTimestamp was null or undefined when calling listCosts.');
                        }
                        if (requestParameters.endTimestamp === null || requestParameters.endTimestamp === undefined) {
                            throw new runtime.RequiredError('endTimestamp', 'Required parameter requestParameters.endTimestamp was null or undefined when calling listCosts.');
                        }
                        queryParameters = {};
                        if (requestParameters.startTimestamp !== undefined) {
                            queryParameters['startTimestamp'] = requestParameters.startTimestamp;
                        }
                        if (requestParameters.endTimestamp !== undefined) {
                            queryParameters['endTimestamp'] = requestParameters.endTimestamp;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/cost",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.GetCostOutputFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * List costs
     */
    DefaultApi.prototype.listCosts = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listCostsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List data product domain data products
     */
    DefaultApi.prototype.listDataProductDomainDataProductsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling listDataProductDomainDataProducts.');
                        }
                        queryParameters = {};
                        if (requestParameters.nextToken !== undefined) {
                            queryParameters['nextToken'] = requestParameters.nextToken;
                        }
                        if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/data-product/domain/{domainId}/data-product".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ListDataProductDomainDataProductsResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * List data product domain data products
     */
    DefaultApi.prototype.listDataProductDomainDataProducts = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listDataProductDomainDataProductsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List data product domains
     */
    DefaultApi.prototype.listDataProductDomainsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.nextToken !== undefined) {
                            queryParameters['nextToken'] = requestParameters.nextToken;
                        }
                        if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/data-product/domain",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ListDataProductDomainsResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * List data product domains
     */
    DefaultApi.prototype.listDataProductDomains = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listDataProductDomainsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List data product scripts
     */
    DefaultApi.prototype.listDataProductScriptsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.nextToken !== undefined) {
                            queryParameters['nextToken'] = requestParameters.nextToken;
                        }
                        if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/data-product/scripts",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ListDataProductScriptsResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * List data product scripts
     */
    DefaultApi.prototype.listDataProductScripts = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listDataProductScriptsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List identity groups
     */
    DefaultApi.prototype.listIdentityGroupsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.nextToken !== undefined) {
                            queryParameters['nextToken'] = requestParameters.nextToken;
                        }
                        if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/identity/group",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ListIdentityGroupsResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * List identity groups
     */
    DefaultApi.prototype.listIdentityGroups = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listIdentityGroupsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List identity machine tokens
     */
    DefaultApi.prototype.listIdentityMachineTokensRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
                            throw new runtime.RequiredError('machineId', 'Required parameter requestParameters.machineId was null or undefined when calling listIdentityMachineTokens.');
                        }
                        queryParameters = {};
                        if (requestParameters.nextToken !== undefined) {
                            queryParameters['nextToken'] = requestParameters.nextToken;
                        }
                        if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/identity/machine/{machineId}/token".replace("{" + "machineId" + "}", encodeURIComponent(String(requestParameters.machineId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ListIdentityMachineTokensResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * List identity machine tokens
     */
    DefaultApi.prototype.listIdentityMachineTokens = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listIdentityMachineTokensRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List identity machines
     */
    DefaultApi.prototype.listIdentityMachinesRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.nextToken !== undefined) {
                            queryParameters['nextToken'] = requestParameters.nextToken;
                        }
                        if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/identity/machine",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ListIdentityMachinesResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * List identity machines
     */
    DefaultApi.prototype.listIdentityMachines = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listIdentityMachinesRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List identity providers
     */
    DefaultApi.prototype.listIdentityProvidersRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.nextToken !== undefined) {
                            queryParameters['nextToken'] = requestParameters.nextToken;
                        }
                        if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/identity/provider",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ListIdentityProvidersResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * List identity providers
     */
    DefaultApi.prototype.listIdentityProviders = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listIdentityProvidersRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List identity requests
     */
    DefaultApi.prototype.listIdentityRequestsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.nextToken !== undefined) {
                            queryParameters['nextToken'] = requestParameters.nextToken;
                        }
                        if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/identity/request",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ListIdentityRequestsResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * List identity requests
     */
    DefaultApi.prototype.listIdentityRequests = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listIdentityRequestsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List identity users
     */
    DefaultApi.prototype.listIdentityUsersRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.filter !== undefined) {
                            queryParameters['filter'] = requestParameters.filter;
                        }
                        if (requestParameters.nextToken !== undefined) {
                            queryParameters['nextToken'] = requestParameters.nextToken;
                        }
                        if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/identity/user",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ListIdentityUsersResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * List identity users
     */
    DefaultApi.prototype.listIdentityUsers = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listIdentityUsersRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List notifications
     */
    DefaultApi.prototype.listNotificationsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.status !== undefined) {
                            queryParameters['status'] = requestParameters.status;
                        }
                        if (requestParameters.nextToken !== undefined) {
                            queryParameters['nextToken'] = requestParameters.nextToken;
                        }
                        if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/notification",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ListNotificationsResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * List notifications
     */
    DefaultApi.prototype.listNotifications = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listNotificationsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List ontologies
     */
    DefaultApi.prototype.listOntologiesRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.nextToken !== undefined) {
                            queryParameters['nextToken'] = requestParameters.nextToken;
                        }
                        if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/ontology",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ListOntologiesResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * List ontologies
     */
    DefaultApi.prototype.listOntologies = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listOntologiesRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List ontology find by aliases
     */
    DefaultApi.prototype.listOntologyFindByAliasesRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.aliases === null || requestParameters.aliases === undefined) {
                            throw new runtime.RequiredError('aliases', 'Required parameter requestParameters.aliases was null or undefined when calling listOntologyFindByAliases.');
                        }
                        queryParameters = {};
                        if (requestParameters.aliases !== undefined) {
                            queryParameters['aliases'] = requestParameters.aliases;
                        }
                        if (requestParameters.nextToken !== undefined) {
                            queryParameters['nextToken'] = requestParameters.nextToken;
                        }
                        if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/ontology/findByAlias",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ListOntologyFindByAliasesResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * List ontology find by aliases
     */
    DefaultApi.prototype.listOntologyFindByAliases = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listOntologyFindByAliasesRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List query histories
     */
    DefaultApi.prototype.listQueryHistoriesRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.nextToken !== undefined) {
                            queryParameters['nextToken'] = requestParameters.nextToken;
                        }
                        if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/query/history",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ListQueryHistoriesResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * List query histories
     */
    DefaultApi.prototype.listQueryHistories = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listQueryHistoriesRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List query namespace saved queries
     */
    DefaultApi.prototype.listQueryNamespaceSavedQueriesRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.namespace === null || requestParameters.namespace === undefined) {
                            throw new runtime.RequiredError('namespace', 'Required parameter requestParameters.namespace was null or undefined when calling listQueryNamespaceSavedQueries.');
                        }
                        queryParameters = {};
                        if (requestParameters.nextToken !== undefined) {
                            queryParameters['nextToken'] = requestParameters.nextToken;
                        }
                        if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/query/saved-query/{namespace}".replace("{" + "namespace" + "}", encodeURIComponent(String(requestParameters.namespace))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.SavedQueryListFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * List query namespace saved queries
     */
    DefaultApi.prototype.listQueryNamespaceSavedQueries = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listQueryNamespaceSavedQueriesRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List query results
     */
    DefaultApi.prototype.listQueryResultsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.executionId === null || requestParameters.executionId === undefined) {
                            throw new runtime.RequiredError('executionId', 'Required parameter requestParameters.executionId was null or undefined when calling listQueryResults.');
                        }
                        queryParameters = {};
                        if (requestParameters.retrieveDataIntegrity !== undefined) {
                            queryParameters['retrieveDataIntegrity'] = requestParameters.retrieveDataIntegrity;
                        }
                        if (requestParameters.nextToken !== undefined) {
                            queryParameters['nextToken'] = requestParameters.nextToken;
                        }
                        if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/query/{executionId}/result".replace("{" + "executionId" + "}", encodeURIComponent(String(requestParameters.executionId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.PaginatedQueryResultFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * List query results
     */
    DefaultApi.prototype.listQueryResults = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listQueryResultsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List query results as athena results
     */
    DefaultApi.prototype.listQueryResultsAsAthenaResultsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.executionId === null || requestParameters.executionId === undefined) {
                            throw new runtime.RequiredError('executionId', 'Required parameter requestParameters.executionId was null or undefined when calling listQueryResultsAsAthenaResults.');
                        }
                        queryParameters = {};
                        if (requestParameters.maxResults !== undefined) {
                            queryParameters['maxResults'] = requestParameters.maxResults;
                        }
                        if (requestParameters.nextToken !== undefined) {
                            queryParameters['nextToken'] = requestParameters.nextToken;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/query/{executionId}/result-as-athena".replace("{" + "executionId" + "}", encodeURIComponent(String(requestParameters.executionId))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.PaginatedQueryResultAsAthenaFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * List query results as athena results
     */
    DefaultApi.prototype.listQueryResultsAsAthenaResults = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listQueryResultsAsAthenaResultsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List query saved queries
     */
    DefaultApi.prototype.listQuerySavedQueriesRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.nextToken !== undefined) {
                            queryParameters['nextToken'] = requestParameters.nextToken;
                        }
                        if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/query/saved-query",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.SavedQueryListFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * List query saved queries
     */
    DefaultApi.prototype.listQuerySavedQueries = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listQuerySavedQueriesRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Post administration budgets
     */
    DefaultApi.prototype.postAdministrationBudgetsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.budgetInput === null || requestParameters.budgetInput === undefined) {
                            throw new runtime.RequiredError('budgetInput', 'Required parameter requestParameters.budgetInput was null or undefined when calling postAdministrationBudgets.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/administration/budgets",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.BudgetInputToJSON(requestParameters.budgetInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.BudgetResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Post administration budgets
     */
    DefaultApi.prototype.postAdministrationBudgets = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.postAdministrationBudgetsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Post administration deploy superset
     */
    DefaultApi.prototype.postAdministrationDeploySupersetRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/administration/deploy-superset",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.SupersetDeployResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Post administration deploy superset
     */
    DefaultApi.prototype.postAdministrationDeploySuperset = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.postAdministrationDeploySupersetRaw(initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Post data product domain data product
     */
    DefaultApi.prototype.postDataProductDomainDataProductRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling postDataProductDomainDataProduct.');
                        }
                        if (requestParameters.dataProductId === null || requestParameters.dataProductId === undefined) {
                            throw new runtime.RequiredError('dataProductId', 'Required parameter requestParameters.dataProductId was null or undefined when calling postDataProductDomainDataProduct.');
                        }
                        if (requestParameters.dataProductInput === null || requestParameters.dataProductInput === undefined) {
                            throw new runtime.RequiredError('dataProductInput', 'Required parameter requestParameters.dataProductInput was null or undefined when calling postDataProductDomainDataProduct.');
                        }
                        queryParameters = {};
                        if (requestParameters.initialFullAccessGroups) {
                            queryParameters['initialFullAccessGroups'] = requestParameters.initialFullAccessGroups;
                        }
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/data-product/domain/{domainId}/data-product/{dataProductId}".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))).replace("{" + "dataProductId" + "}", encodeURIComponent(String(requestParameters.dataProductId))),
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.DataProductInputToJSON(requestParameters.dataProductInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DataProductEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Post data product domain data product
     */
    DefaultApi.prototype.postDataProductDomainDataProduct = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.postDataProductDomainDataProductRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Post data product domain data product file upload
     */
    DefaultApi.prototype.postDataProductDomainDataProductFileUploadRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.contentType === null || requestParameters.contentType === undefined) {
                            throw new runtime.RequiredError('contentType', 'Required parameter requestParameters.contentType was null or undefined when calling postDataProductDomainDataProductFileUpload.');
                        }
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling postDataProductDomainDataProductFileUpload.');
                        }
                        if (requestParameters.dataProductId === null || requestParameters.dataProductId === undefined) {
                            throw new runtime.RequiredError('dataProductId', 'Required parameter requestParameters.dataProductId was null or undefined when calling postDataProductDomainDataProductFileUpload.');
                        }
                        if (requestParameters.fileName === null || requestParameters.fileName === undefined) {
                            throw new runtime.RequiredError('fileName', 'Required parameter requestParameters.fileName was null or undefined when calling postDataProductDomainDataProductFileUpload.');
                        }
                        queryParameters = {};
                        if (requestParameters.contentType !== undefined) {
                            queryParameters['contentType'] = requestParameters.contentType;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/data-product/domain/{domainId}/data-product/{dataProductId}/file/upload/{fileName}".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))).replace("{" + "dataProductId" + "}", encodeURIComponent(String(requestParameters.dataProductId))).replace("{" + "fileName" + "}", encodeURIComponent(String(requestParameters.fileName))),
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DataProductMultipartFileUploadStartedFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Post data product domain data product file upload
     */
    DefaultApi.prototype.postDataProductDomainDataProductFileUpload = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.postDataProductDomainDataProductFileUploadRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Post data product preview domain data product
     */
    DefaultApi.prototype.postDataProductPreviewDomainDataProductRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling postDataProductPreviewDomainDataProduct.');
                        }
                        if (requestParameters.dataProductId === null || requestParameters.dataProductId === undefined) {
                            throw new runtime.RequiredError('dataProductId', 'Required parameter requestParameters.dataProductId was null or undefined when calling postDataProductPreviewDomainDataProduct.');
                        }
                        if (requestParameters.dataProductPreviewInput === null || requestParameters.dataProductPreviewInput === undefined) {
                            throw new runtime.RequiredError('dataProductPreviewInput', 'Required parameter requestParameters.dataProductPreviewInput was null or undefined when calling postDataProductPreviewDomainDataProduct.');
                        }
                        queryParameters = {};
                        if (requestParameters.sampleSize !== undefined) {
                            queryParameters['sampleSize'] = requestParameters.sampleSize;
                        }
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/data-product-preview/domain/{domainId}/data-product/{dataProductId}".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))).replace("{" + "dataProductId" + "}", encodeURIComponent(String(requestParameters.dataProductId))),
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.DataProductPreviewInputToJSON(requestParameters.dataProductPreviewInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DataProductPreviewIdentifierFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Post data product preview domain data product
     */
    DefaultApi.prototype.postDataProductPreviewDomainDataProduct = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.postDataProductPreviewDomainDataProductRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Post data product scripts validate
     */
    DefaultApi.prototype.postDataProductScriptsValidateRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.scriptSourceValidationInput === null || requestParameters.scriptSourceValidationInput === undefined) {
                            throw new runtime.RequiredError('scriptSourceValidationInput', 'Required parameter requestParameters.scriptSourceValidationInput was null or undefined when calling postDataProductScriptsValidate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/data-product/scripts/validate",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.ScriptSourceValidationInputToJSON(requestParameters.scriptSourceValidationInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ScriptSourceValidationOutputFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Post data product scripts validate
     */
    DefaultApi.prototype.postDataProductScriptsValidate = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.postDataProductScriptsValidateRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Post query
     */
    DefaultApi.prototype.postQueryRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.query === null || requestParameters.query === undefined) {
                            throw new runtime.RequiredError('query', 'Required parameter requestParameters.query was null or undefined when calling postQuery.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/query",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.QueryToJSON(requestParameters.query),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.QueryExecutionFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Post query
     */
    DefaultApi.prototype.postQuery = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.postQueryRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Post query generate
     */
    DefaultApi.prototype.postQueryGenerateRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.query === null || requestParameters.query === undefined) {
                            throw new runtime.RequiredError('query', 'Required parameter requestParameters.query was null or undefined when calling postQueryGenerate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/query/generate",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.QueryToJSON(requestParameters.query),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.GeneratedQueryFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Post query generate
     */
    DefaultApi.prototype.postQueryGenerate = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.postQueryGenerateRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Post query parse render discover
     */
    DefaultApi.prototype.postQueryParseRenderDiscoverRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.query === null || requestParameters.query === undefined) {
                            throw new runtime.RequiredError('query', 'Required parameter requestParameters.query was null or undefined when calling postQueryParseRenderDiscover.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/query-parse-render/discover",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.QueryToJSON(requestParameters.query),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.PostQueryParseRenderDiscoverResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Post query parse render discover
     */
    DefaultApi.prototype.postQueryParseRenderDiscover = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.postQueryParseRenderDiscoverRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Post query parse render rewrite
     */
    DefaultApi.prototype.postQueryParseRenderRewriteRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.queryRewriteInput === null || requestParameters.queryRewriteInput === undefined) {
                            throw new runtime.RequiredError('queryRewriteInput', 'Required parameter requestParameters.queryRewriteInput was null or undefined when calling postQueryParseRenderRewrite.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/query-parse-render/rewrite",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.QueryRewriteInputToJSON(requestParameters.queryRewriteInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.QueryFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Post query parse render rewrite
     */
    DefaultApi.prototype.postQueryParseRenderRewrite = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.postQueryParseRenderRewriteRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Post query parse render validate attribute value policy
     */
    DefaultApi.prototype.postQueryParseRenderValidateAttributeValuePolicyRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.postQueryParseRenderValidateAttributeValuePolicyRequest === null || requestParameters.postQueryParseRenderValidateAttributeValuePolicyRequest === undefined) {
                            throw new runtime.RequiredError('postQueryParseRenderValidateAttributeValuePolicyRequest', 'Required parameter requestParameters.postQueryParseRenderValidateAttributeValuePolicyRequest was null or undefined when calling postQueryParseRenderValidateAttributeValuePolicy.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/query-parse-render/validate-attribute-value-policy",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.PostQueryParseRenderValidateAttributeValuePolicyRequestToJSON(requestParameters.postQueryParseRenderValidateAttributeValuePolicyRequest),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Post query parse render validate attribute value policy
     */
    DefaultApi.prototype.postQueryParseRenderValidateAttributeValuePolicy = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.postQueryParseRenderValidateAttributeValuePolicyRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put api access policy
     */
    DefaultApi.prototype.putApiAccessPolicyRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.apiAccessPolicyId === null || requestParameters.apiAccessPolicyId === undefined) {
                            throw new runtime.RequiredError('apiAccessPolicyId', 'Required parameter requestParameters.apiAccessPolicyId was null or undefined when calling putApiAccessPolicy.');
                        }
                        if (requestParameters.apiAccessPolicyInput === null || requestParameters.apiAccessPolicyInput === undefined) {
                            throw new runtime.RequiredError('apiAccessPolicyInput', 'Required parameter requestParameters.apiAccessPolicyInput was null or undefined when calling putApiAccessPolicy.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/api-access-policy/{apiAccessPolicyId}".replace("{" + "apiAccessPolicyId" + "}", encodeURIComponent(String(requestParameters.apiAccessPolicyId))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.ApiAccessPolicyInputToJSON(requestParameters.apiAccessPolicyInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ApiAccessPolicyEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put api access policy
     */
    DefaultApi.prototype.putApiAccessPolicy = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putApiAccessPolicyRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put data product domain
     */
    DefaultApi.prototype.putDataProductDomainRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling putDataProductDomain.');
                        }
                        if (requestParameters.domainInput === null || requestParameters.domainInput === undefined) {
                            throw new runtime.RequiredError('domainInput', 'Required parameter requestParameters.domainInput was null or undefined when calling putDataProductDomain.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/data-product/domain/{domainId}".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.DomainInputToJSON(requestParameters.domainInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DomainEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put data product domain
     */
    DefaultApi.prototype.putDataProductDomain = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putDataProductDomainRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put data product domain data product
     */
    DefaultApi.prototype.putDataProductDomainDataProductRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling putDataProductDomainDataProduct.');
                        }
                        if (requestParameters.dataProductId === null || requestParameters.dataProductId === undefined) {
                            throw new runtime.RequiredError('dataProductId', 'Required parameter requestParameters.dataProductId was null or undefined when calling putDataProductDomainDataProduct.');
                        }
                        if (requestParameters.dataProductInput === null || requestParameters.dataProductInput === undefined) {
                            throw new runtime.RequiredError('dataProductInput', 'Required parameter requestParameters.dataProductInput was null or undefined when calling putDataProductDomainDataProduct.');
                        }
                        queryParameters = {};
                        if (requestParameters.initialFullAccessGroups) {
                            queryParameters['initialFullAccessGroups'] = requestParameters.initialFullAccessGroups;
                        }
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/data-product/domain/{domainId}/data-product/{dataProductId}".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))).replace("{" + "dataProductId" + "}", encodeURIComponent(String(requestParameters.dataProductId))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.DataProductInputToJSON(requestParameters.dataProductInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DataProductEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put data product domain data product
     */
    DefaultApi.prototype.putDataProductDomainDataProduct = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putDataProductDomainDataProductRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put data product domain data product file upload
     */
    DefaultApi.prototype.putDataProductDomainDataProductFileUploadRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.uploadId === null || requestParameters.uploadId === undefined) {
                            throw new runtime.RequiredError('uploadId', 'Required parameter requestParameters.uploadId was null or undefined when calling putDataProductDomainDataProductFileUpload.');
                        }
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling putDataProductDomainDataProductFileUpload.');
                        }
                        if (requestParameters.dataProductId === null || requestParameters.dataProductId === undefined) {
                            throw new runtime.RequiredError('dataProductId', 'Required parameter requestParameters.dataProductId was null or undefined when calling putDataProductDomainDataProductFileUpload.');
                        }
                        if (requestParameters.fileName === null || requestParameters.fileName === undefined) {
                            throw new runtime.RequiredError('fileName', 'Required parameter requestParameters.fileName was null or undefined when calling putDataProductDomainDataProductFileUpload.');
                        }
                        if (requestParameters.fileUploadInput === null || requestParameters.fileUploadInput === undefined) {
                            throw new runtime.RequiredError('fileUploadInput', 'Required parameter requestParameters.fileUploadInput was null or undefined when calling putDataProductDomainDataProductFileUpload.');
                        }
                        queryParameters = {};
                        if (requestParameters.uploadId !== undefined) {
                            queryParameters['uploadId'] = requestParameters.uploadId;
                        }
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/data-product/domain/{domainId}/data-product/{dataProductId}/file/upload/{fileName}".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))).replace("{" + "dataProductId" + "}", encodeURIComponent(String(requestParameters.dataProductId))).replace("{" + "fileName" + "}", encodeURIComponent(String(requestParameters.fileName))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.FileUploadInputToJSON(requestParameters.fileUploadInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.S3LocationFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put data product domain data product file upload
     */
    DefaultApi.prototype.putDataProductDomainDataProductFileUpload = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putDataProductDomainDataProductFileUploadRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put data product domain data product start data update
     */
    DefaultApi.prototype.putDataProductDomainDataProductStartDataUpdateRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling putDataProductDomainDataProductStartDataUpdate.');
                        }
                        if (requestParameters.dataProductId === null || requestParameters.dataProductId === undefined) {
                            throw new runtime.RequiredError('dataProductId', 'Required parameter requestParameters.dataProductId was null or undefined when calling putDataProductDomainDataProductStartDataUpdate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/data-product/domain/{domainId}/data-product/{dataProductId}/start-data-update".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))).replace("{" + "dataProductId" + "}", encodeURIComponent(String(requestParameters.dataProductId))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DataProductEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put data product domain data product start data update
     */
    DefaultApi.prototype.putDataProductDomainDataProductStartDataUpdate = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putDataProductDomainDataProductStartDataUpdateRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put data product scripts namespace script
     */
    DefaultApi.prototype.putDataProductScriptsNamespaceScriptRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.namespace === null || requestParameters.namespace === undefined) {
                            throw new runtime.RequiredError('namespace', 'Required parameter requestParameters.namespace was null or undefined when calling putDataProductScriptsNamespaceScript.');
                        }
                        if (requestParameters.scriptId === null || requestParameters.scriptId === undefined) {
                            throw new runtime.RequiredError('scriptId', 'Required parameter requestParameters.scriptId was null or undefined when calling putDataProductScriptsNamespaceScript.');
                        }
                        if (requestParameters.scriptInput === null || requestParameters.scriptInput === undefined) {
                            throw new runtime.RequiredError('scriptInput', 'Required parameter requestParameters.scriptInput was null or undefined when calling putDataProductScriptsNamespaceScript.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/data-product/scripts/namespace/{namespace}/script/{scriptId}".replace("{" + "namespace" + "}", encodeURIComponent(String(requestParameters.namespace))).replace("{" + "scriptId" + "}", encodeURIComponent(String(requestParameters.scriptId))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.ScriptInputToJSON(requestParameters.scriptInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ScriptEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put data product scripts namespace script
     */
    DefaultApi.prototype.putDataProductScriptsNamespaceScript = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putDataProductScriptsNamespaceScriptRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put governance policy attribute values
     */
    DefaultApi.prototype.putGovernancePolicyAttributeValuesRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.putGovernancePolicyAttributeValuesRequest === null || requestParameters.putGovernancePolicyAttributeValuesRequest === undefined) {
                            throw new runtime.RequiredError('putGovernancePolicyAttributeValuesRequest', 'Required parameter requestParameters.putGovernancePolicyAttributeValuesRequest was null or undefined when calling putGovernancePolicyAttributeValues.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/attribute-values",
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.PutGovernancePolicyAttributeValuesRequestToJSON(requestParameters.putGovernancePolicyAttributeValuesRequest),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.PutGovernancePolicyAttributeValuesResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put governance policy attribute values
     */
    DefaultApi.prototype.putGovernancePolicyAttributeValues = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putGovernancePolicyAttributeValuesRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put governance policy attribute values group
     */
    DefaultApi.prototype.putGovernancePolicyAttributeValuesGroupRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.ontologyNamespace === null || requestParameters.ontologyNamespace === undefined) {
                            throw new runtime.RequiredError('ontologyNamespace', 'Required parameter requestParameters.ontologyNamespace was null or undefined when calling putGovernancePolicyAttributeValuesGroup.');
                        }
                        if (requestParameters.attributeId === null || requestParameters.attributeId === undefined) {
                            throw new runtime.RequiredError('attributeId', 'Required parameter requestParameters.attributeId was null or undefined when calling putGovernancePolicyAttributeValuesGroup.');
                        }
                        if (requestParameters.group === null || requestParameters.group === undefined) {
                            throw new runtime.RequiredError('group', 'Required parameter requestParameters.group was null or undefined when calling putGovernancePolicyAttributeValuesGroup.');
                        }
                        if (requestParameters.attributeValuePolicyInput === null || requestParameters.attributeValuePolicyInput === undefined) {
                            throw new runtime.RequiredError('attributeValuePolicyInput', 'Required parameter requestParameters.attributeValuePolicyInput was null or undefined when calling putGovernancePolicyAttributeValuesGroup.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/attribute-values/{ontologyNamespace}/{attributeId}/group/{group}".replace("{" + "ontologyNamespace" + "}", encodeURIComponent(String(requestParameters.ontologyNamespace))).replace("{" + "attributeId" + "}", encodeURIComponent(String(requestParameters.attributeId))).replace("{" + "group" + "}", encodeURIComponent(String(requestParameters.group))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.AttributeValuePolicyInputToJSON(requestParameters.attributeValuePolicyInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.AttributeValuePolicyEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put governance policy attribute values group
     */
    DefaultApi.prototype.putGovernancePolicyAttributeValuesGroup = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putGovernancePolicyAttributeValuesGroupRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put governance policy attributes
     */
    DefaultApi.prototype.putGovernancePolicyAttributesRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.putGovernancePolicyAttributesRequest === null || requestParameters.putGovernancePolicyAttributesRequest === undefined) {
                            throw new runtime.RequiredError('putGovernancePolicyAttributesRequest', 'Required parameter requestParameters.putGovernancePolicyAttributesRequest was null or undefined when calling putGovernancePolicyAttributes.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/attributes",
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.PutGovernancePolicyAttributesRequestToJSON(requestParameters.putGovernancePolicyAttributesRequest),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.PutGovernancePolicyAttributesResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put governance policy attributes
     */
    DefaultApi.prototype.putGovernancePolicyAttributes = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putGovernancePolicyAttributesRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put governance policy attributes group
     */
    DefaultApi.prototype.putGovernancePolicyAttributesGroupRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.ontologyNamespace === null || requestParameters.ontologyNamespace === undefined) {
                            throw new runtime.RequiredError('ontologyNamespace', 'Required parameter requestParameters.ontologyNamespace was null or undefined when calling putGovernancePolicyAttributesGroup.');
                        }
                        if (requestParameters.attributeId === null || requestParameters.attributeId === undefined) {
                            throw new runtime.RequiredError('attributeId', 'Required parameter requestParameters.attributeId was null or undefined when calling putGovernancePolicyAttributesGroup.');
                        }
                        if (requestParameters.group === null || requestParameters.group === undefined) {
                            throw new runtime.RequiredError('group', 'Required parameter requestParameters.group was null or undefined when calling putGovernancePolicyAttributesGroup.');
                        }
                        if (requestParameters.attributePolicyInput === null || requestParameters.attributePolicyInput === undefined) {
                            throw new runtime.RequiredError('attributePolicyInput', 'Required parameter requestParameters.attributePolicyInput was null or undefined when calling putGovernancePolicyAttributesGroup.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/attributes/{ontologyNamespace}/{attributeId}/group/{group}".replace("{" + "ontologyNamespace" + "}", encodeURIComponent(String(requestParameters.ontologyNamespace))).replace("{" + "attributeId" + "}", encodeURIComponent(String(requestParameters.attributeId))).replace("{" + "group" + "}", encodeURIComponent(String(requestParameters.group))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.AttributePolicyInputToJSON(requestParameters.attributePolicyInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.AttributePolicyEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put governance policy attributes group
     */
    DefaultApi.prototype.putGovernancePolicyAttributesGroup = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putGovernancePolicyAttributesGroupRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put governance policy default lens domain data product
     */
    DefaultApi.prototype.putGovernancePolicyDefaultLensDomainDataProductRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling putGovernancePolicyDefaultLensDomainDataProduct.');
                        }
                        if (requestParameters.dataProductId === null || requestParameters.dataProductId === undefined) {
                            throw new runtime.RequiredError('dataProductId', 'Required parameter requestParameters.dataProductId was null or undefined when calling putGovernancePolicyDefaultLensDomainDataProduct.');
                        }
                        if (requestParameters.defaultLensPolicyInput === null || requestParameters.defaultLensPolicyInput === undefined) {
                            throw new runtime.RequiredError('defaultLensPolicyInput', 'Required parameter requestParameters.defaultLensPolicyInput was null or undefined when calling putGovernancePolicyDefaultLensDomainDataProduct.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/default-lens/domain/{domainId}/data-product/{dataProductId}".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))).replace("{" + "dataProductId" + "}", encodeURIComponent(String(requestParameters.dataProductId))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.DefaultLensPolicyInputToJSON(requestParameters.defaultLensPolicyInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DefaultLensPolicyEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put governance policy default lens domain data product
     */
    DefaultApi.prototype.putGovernancePolicyDefaultLensDomainDataProduct = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putGovernancePolicyDefaultLensDomainDataProductRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put governance policy domain data product
     */
    DefaultApi.prototype.putGovernancePolicyDomainDataProductRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                            throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling putGovernancePolicyDomainDataProduct.');
                        }
                        if (requestParameters.dataProductId === null || requestParameters.dataProductId === undefined) {
                            throw new runtime.RequiredError('dataProductId', 'Required parameter requestParameters.dataProductId was null or undefined when calling putGovernancePolicyDomainDataProduct.');
                        }
                        if (requestParameters.dataProductPolicyInput === null || requestParameters.dataProductPolicyInput === undefined) {
                            throw new runtime.RequiredError('dataProductPolicyInput', 'Required parameter requestParameters.dataProductPolicyInput was null or undefined when calling putGovernancePolicyDomainDataProduct.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/governance/policy/domain/{domainId}/data-product/{dataProductId}".replace("{" + "domainId" + "}", encodeURIComponent(String(requestParameters.domainId))).replace("{" + "dataProductId" + "}", encodeURIComponent(String(requestParameters.dataProductId))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.DataProductPolicyInputToJSON(requestParameters.dataProductPolicyInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DataProductPolicyEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put governance policy domain data product
     */
    DefaultApi.prototype.putGovernancePolicyDomainDataProduct = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putGovernancePolicyDomainDataProductRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put identity group
     */
    DefaultApi.prototype.putIdentityGroupRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
                            throw new runtime.RequiredError('groupId', 'Required parameter requestParameters.groupId was null or undefined when calling putIdentityGroup.');
                        }
                        if (requestParameters.groupInput === null || requestParameters.groupInput === undefined) {
                            throw new runtime.RequiredError('groupInput', 'Required parameter requestParameters.groupInput was null or undefined when calling putIdentityGroup.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/identity/group/{groupId}".replace("{" + "groupId" + "}", encodeURIComponent(String(requestParameters.groupId))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.GroupInputToJSON(requestParameters.groupInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.GroupEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put identity group
     */
    DefaultApi.prototype.putIdentityGroup = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putIdentityGroupRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put identity group members
     */
    DefaultApi.prototype.putIdentityGroupMembersRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
                            throw new runtime.RequiredError('groupId', 'Required parameter requestParameters.groupId was null or undefined when calling putIdentityGroupMembers.');
                        }
                        if (requestParameters.groupMembersInput === null || requestParameters.groupMembersInput === undefined) {
                            throw new runtime.RequiredError('groupMembersInput', 'Required parameter requestParameters.groupMembersInput was null or undefined when calling putIdentityGroupMembers.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/identity/group/{groupId}/members".replace("{" + "groupId" + "}", encodeURIComponent(String(requestParameters.groupId))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.GroupMembersInputToJSON(requestParameters.groupMembersInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.GroupEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put identity group members
     */
    DefaultApi.prototype.putIdentityGroupMembers = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putIdentityGroupMembersRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put identity machine
     */
    DefaultApi.prototype.putIdentityMachineRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
                            throw new runtime.RequiredError('machineId', 'Required parameter requestParameters.machineId was null or undefined when calling putIdentityMachine.');
                        }
                        if (requestParameters.machineInput === null || requestParameters.machineInput === undefined) {
                            throw new runtime.RequiredError('machineInput', 'Required parameter requestParameters.machineInput was null or undefined when calling putIdentityMachine.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/identity/machine/{machineId}".replace("{" + "machineId" + "}", encodeURIComponent(String(requestParameters.machineId))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.MachineInputToJSON(requestParameters.machineInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.MachineEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put identity machine
     */
    DefaultApi.prototype.putIdentityMachine = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putIdentityMachineRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put identity machine token
     */
    DefaultApi.prototype.putIdentityMachineTokenRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
                            throw new runtime.RequiredError('machineId', 'Required parameter requestParameters.machineId was null or undefined when calling putIdentityMachineToken.');
                        }
                        if (requestParameters.tokenId === null || requestParameters.tokenId === undefined) {
                            throw new runtime.RequiredError('tokenId', 'Required parameter requestParameters.tokenId was null or undefined when calling putIdentityMachineToken.');
                        }
                        if (requestParameters.tokenInput === null || requestParameters.tokenInput === undefined) {
                            throw new runtime.RequiredError('tokenInput', 'Required parameter requestParameters.tokenInput was null or undefined when calling putIdentityMachineToken.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/identity/machine/{machineId}/token/{tokenId}".replace("{" + "machineId" + "}", encodeURIComponent(String(requestParameters.machineId))).replace("{" + "tokenId" + "}", encodeURIComponent(String(requestParameters.tokenId))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.TokenInputToJSON(requestParameters.tokenInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.TokenEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put identity machine token
     */
    DefaultApi.prototype.putIdentityMachineToken = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putIdentityMachineTokenRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put identity provider
     */
    DefaultApi.prototype.putIdentityProviderRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.identityProviderId === null || requestParameters.identityProviderId === undefined) {
                            throw new runtime.RequiredError('identityProviderId', 'Required parameter requestParameters.identityProviderId was null or undefined when calling putIdentityProvider.');
                        }
                        if (requestParameters.identityProviderInput === null || requestParameters.identityProviderInput === undefined) {
                            throw new runtime.RequiredError('identityProviderInput', 'Required parameter requestParameters.identityProviderInput was null or undefined when calling putIdentityProvider.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/identity/provider/{identityProviderId}".replace("{" + "identityProviderId" + "}", encodeURIComponent(String(requestParameters.identityProviderId))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.IdentityProviderInputToJSON(requestParameters.identityProviderInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.IdentityProviderEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put identity provider
     */
    DefaultApi.prototype.putIdentityProvider = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putIdentityProviderRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put identity request
     */
    DefaultApi.prototype.putIdentityRequestRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
                            throw new runtime.RequiredError('groupId', 'Required parameter requestParameters.groupId was null or undefined when calling putIdentityRequest.');
                        }
                        if (requestParameters.userId === null || requestParameters.userId === undefined) {
                            throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling putIdentityRequest.');
                        }
                        if (requestParameters.accessRequestInput === null || requestParameters.accessRequestInput === undefined) {
                            throw new runtime.RequiredError('accessRequestInput', 'Required parameter requestParameters.accessRequestInput was null or undefined when calling putIdentityRequest.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/identity/request/{groupId}/{userId}".replace("{" + "groupId" + "}", encodeURIComponent(String(requestParameters.groupId))).replace("{" + "userId" + "}", encodeURIComponent(String(requestParameters.userId))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.AccessRequestInputToJSON(requestParameters.accessRequestInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.AccessRequestEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put identity request
     */
    DefaultApi.prototype.putIdentityRequest = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putIdentityRequestRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put identity request action
     */
    DefaultApi.prototype.putIdentityRequestActionRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
                            throw new runtime.RequiredError('groupId', 'Required parameter requestParameters.groupId was null or undefined when calling putIdentityRequestAction.');
                        }
                        if (requestParameters.userId === null || requestParameters.userId === undefined) {
                            throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling putIdentityRequestAction.');
                        }
                        if (requestParameters.action === null || requestParameters.action === undefined) {
                            throw new runtime.RequiredError('action', 'Required parameter requestParameters.action was null or undefined when calling putIdentityRequestAction.');
                        }
                        if (requestParameters.putIdentityRequestActionRequest === null || requestParameters.putIdentityRequestActionRequest === undefined) {
                            throw new runtime.RequiredError('putIdentityRequestActionRequest', 'Required parameter requestParameters.putIdentityRequestActionRequest was null or undefined when calling putIdentityRequestAction.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/identity/request/{groupId}/{userId}/{action}".replace("{" + "groupId" + "}", encodeURIComponent(String(requestParameters.groupId))).replace("{" + "userId" + "}", encodeURIComponent(String(requestParameters.userId))).replace("{" + "action" + "}", encodeURIComponent(String(requestParameters.action))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.PutIdentityRequestActionRequestToJSON(requestParameters.putIdentityRequestActionRequest),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.PutIdentityRequestActionResponseFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put identity request action
     */
    DefaultApi.prototype.putIdentityRequestAction = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putIdentityRequestActionRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put notification action
     */
    DefaultApi.prototype.putNotificationActionRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
                            throw new runtime.RequiredError('notificationId', 'Required parameter requestParameters.notificationId was null or undefined when calling putNotificationAction.');
                        }
                        if (requestParameters.persistedNotificationStatus === null || requestParameters.persistedNotificationStatus === undefined) {
                            throw new runtime.RequiredError('persistedNotificationStatus', 'Required parameter requestParameters.persistedNotificationStatus was null or undefined when calling putNotificationAction.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/notification/{notificationId}/action".replace("{" + "notificationId" + "}", encodeURIComponent(String(requestParameters.notificationId))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.PersistedNotificationStatusToJSON(requestParameters.persistedNotificationStatus),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.PersistedNotificationEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put notification action
     */
    DefaultApi.prototype.putNotificationAction = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putNotificationActionRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put ontology
     */
    DefaultApi.prototype.putOntologyRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.ontologyNamespace === null || requestParameters.ontologyNamespace === undefined) {
                            throw new runtime.RequiredError('ontologyNamespace', 'Required parameter requestParameters.ontologyNamespace was null or undefined when calling putOntology.');
                        }
                        if (requestParameters.ontologyId === null || requestParameters.ontologyId === undefined) {
                            throw new runtime.RequiredError('ontologyId', 'Required parameter requestParameters.ontologyId was null or undefined when calling putOntology.');
                        }
                        if (requestParameters.ontologyInput === null || requestParameters.ontologyInput === undefined) {
                            throw new runtime.RequiredError('ontologyInput', 'Required parameter requestParameters.ontologyInput was null or undefined when calling putOntology.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/ontology/{ontologyNamespace}/{ontologyId}".replace("{" + "ontologyNamespace" + "}", encodeURIComponent(String(requestParameters.ontologyNamespace))).replace("{" + "ontologyId" + "}", encodeURIComponent(String(requestParameters.ontologyId))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.OntologyInputToJSON(requestParameters.ontologyInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.OntologyEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put ontology
     */
    DefaultApi.prototype.putOntology = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putOntologyRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Put query saved query
     */
    DefaultApi.prototype.putQuerySavedQueryRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.namespace === null || requestParameters.namespace === undefined) {
                            throw new runtime.RequiredError('namespace', 'Required parameter requestParameters.namespace was null or undefined when calling putQuerySavedQuery.');
                        }
                        if (requestParameters.queryId === null || requestParameters.queryId === undefined) {
                            throw new runtime.RequiredError('queryId', 'Required parameter requestParameters.queryId was null or undefined when calling putQuerySavedQuery.');
                        }
                        if (requestParameters.savedQueryInput === null || requestParameters.savedQueryInput === undefined) {
                            throw new runtime.RequiredError('savedQueryInput', 'Required parameter requestParameters.savedQueryInput was null or undefined when calling putQuerySavedQuery.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/query/saved-query/{namespace}/{queryId}".replace("{" + "namespace" + "}", encodeURIComponent(String(requestParameters.namespace))).replace("{" + "queryId" + "}", encodeURIComponent(String(requestParameters.queryId))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.SavedQueryInputToJSON(requestParameters.savedQueryInput),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.SavedQueryEntityFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Put query saved query
     */
    DefaultApi.prototype.putQuerySavedQuery = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putQuerySavedQueryRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return DefaultApi;
}(runtime.BaseAPI));
exports.DefaultApi = DefaultApi;
/**
    * @export
    * @enum {string}
    */
var GetDataProductDomainDataProductFileUploadContentTypeEnum;
(function (GetDataProductDomainDataProductFileUploadContentTypeEnum) {
    GetDataProductDomainDataProductFileUploadContentTypeEnum["ApplicationOctetStream"] = "application/octet-stream";
    GetDataProductDomainDataProductFileUploadContentTypeEnum["ApplicationJson"] = "application/json";
    GetDataProductDomainDataProductFileUploadContentTypeEnum["TextCsv"] = "text/csv";
    GetDataProductDomainDataProductFileUploadContentTypeEnum["ApplicationGzip"] = "application/gzip";
})(GetDataProductDomainDataProductFileUploadContentTypeEnum = exports.GetDataProductDomainDataProductFileUploadContentTypeEnum || (exports.GetDataProductDomainDataProductFileUploadContentTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var PostDataProductDomainDataProductFileUploadContentTypeEnum;
(function (PostDataProductDomainDataProductFileUploadContentTypeEnum) {
    PostDataProductDomainDataProductFileUploadContentTypeEnum["ApplicationOctetStream"] = "application/octet-stream";
    PostDataProductDomainDataProductFileUploadContentTypeEnum["ApplicationJson"] = "application/json";
    PostDataProductDomainDataProductFileUploadContentTypeEnum["TextCsv"] = "text/csv";
    PostDataProductDomainDataProductFileUploadContentTypeEnum["ApplicationGzip"] = "application/gzip";
})(PostDataProductDomainDataProductFileUploadContentTypeEnum = exports.PostDataProductDomainDataProductFileUploadContentTypeEnum || (exports.PostDataProductDomainDataProductFileUploadContentTypeEnum = {}));

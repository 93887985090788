"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryResultToJSON = exports.QueryResultFromJSONTyped = exports.QueryResultFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function QueryResultFromJSON(json) {
    return QueryResultFromJSONTyped(json, false);
}
exports.QueryResultFromJSON = QueryResultFromJSON;
function QueryResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'columns': (json['columns'].map(_1.QueryResultColumnMetadataFromJSON)),
        'data': (json['data'].map(_1.QueryResultDataFromJSON)),
        'dataIntegrity': !runtime_1.exists(json, 'dataIntegrity') ? undefined : _1.DataIntegrityEnumFromJSON(json['dataIntegrity']),
    };
}
exports.QueryResultFromJSONTyped = QueryResultFromJSONTyped;
function QueryResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'columns': (value.columns.map(_1.QueryResultColumnMetadataToJSON)),
        'data': (value.data.map(_1.QueryResultDataToJSON)),
        'dataIntegrity': _1.DataIntegrityEnumToJSON(value.dataIntegrity),
    };
}
exports.QueryResultToJSON = QueryResultToJSON;

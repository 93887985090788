"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryResultColumnMetadataToJSON = exports.QueryResultColumnMetadataFromJSONTyped = exports.QueryResultColumnMetadataFromJSON = void 0;
var runtime_1 = require("../runtime");
function QueryResultColumnMetadataFromJSON(json) {
    return QueryResultColumnMetadataFromJSONTyped(json, false);
}
exports.QueryResultColumnMetadataFromJSON = QueryResultColumnMetadataFromJSON;
function QueryResultColumnMetadataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'caseSensitive': !runtime_1.exists(json, 'caseSensitive') ? undefined : json['caseSensitive'],
        'label': !runtime_1.exists(json, 'label') ? undefined : json['label'],
        'name': json['name'],
        'nullable': !runtime_1.exists(json, 'nullable') ? undefined : json['nullable'],
        'precision': !runtime_1.exists(json, 'precision') ? undefined : json['precision'],
        'scale': !runtime_1.exists(json, 'scale') ? undefined : json['scale'],
        'type': json['type'],
    };
}
exports.QueryResultColumnMetadataFromJSONTyped = QueryResultColumnMetadataFromJSONTyped;
function QueryResultColumnMetadataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'caseSensitive': value.caseSensitive,
        'label': value.label,
        'name': value.name,
        'nullable': value.nullable,
        'precision': value.precision,
        'scale': value.scale,
        'type': value.type,
    };
}
exports.QueryResultColumnMetadataToJSON = QueryResultColumnMetadataToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScriptVulnerabilityReportToJSON = exports.ScriptVulnerabilityReportFromJSONTyped = exports.ScriptVulnerabilityReportFromJSON = void 0;
var _1 = require("./");
function ScriptVulnerabilityReportFromJSON(json) {
    return ScriptVulnerabilityReportFromJSONTyped(json, false);
}
exports.ScriptVulnerabilityReportFromJSON = ScriptVulnerabilityReportFromJSON;
function ScriptVulnerabilityReportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'errors': json['errors'],
        'generatedAt': json['generatedAt'],
        'metrics': _1.ScriptVulnerabilityReportMetricsFromJSON(json['metrics']),
        'passed': json['passed'],
        'results': (json['results'].map(_1.ScriptVulnerabilityReportResultsFromJSON)),
    };
}
exports.ScriptVulnerabilityReportFromJSONTyped = ScriptVulnerabilityReportFromJSONTyped;
function ScriptVulnerabilityReportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'errors': value.errors,
        'generatedAt': value.generatedAt,
        'metrics': _1.ScriptVulnerabilityReportMetricsToJSON(value.metrics),
        'passed': value.passed,
        'results': (value.results.map(_1.ScriptVulnerabilityReportResultsToJSON)),
    };
}
exports.ScriptVulnerabilityReportToJSON = ScriptVulnerabilityReportToJSON;

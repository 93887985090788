"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataSetIdentifiersToJSON = exports.DataSetIdentifiersFromJSONTyped = exports.DataSetIdentifiersFromJSON = void 0;
var runtime_1 = require("../runtime");
function DataSetIdentifiersFromJSON(json) {
    return DataSetIdentifiersFromJSONTyped(json, false);
}
exports.DataSetIdentifiersFromJSON = DataSetIdentifiersFromJSON;
function DataSetIdentifiersFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'catalog': !runtime_1.exists(json, 'catalog') ? undefined : json['catalog'],
        'database': !runtime_1.exists(json, 'database') ? undefined : json['database'],
        'table': !runtime_1.exists(json, 'table') ? undefined : json['table'],
    };
}
exports.DataSetIdentifiersFromJSONTyped = DataSetIdentifiersFromJSONTyped;
function DataSetIdentifiersToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'catalog': value.catalog,
        'database': value.database,
        'table': value.table,
    };
}
exports.DataSetIdentifiersToJSON = DataSetIdentifiersToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityAttributeToJSON = exports.IdentityAttributeFromJSONTyped = exports.IdentityAttributeFromJSON = void 0;
function IdentityAttributeFromJSON(json) {
    return IdentityAttributeFromJSONTyped(json, false);
}
exports.IdentityAttributeFromJSON = IdentityAttributeFromJSON;
function IdentityAttributeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'required': json['required'],
        'type': json['type'],
    };
}
exports.IdentityAttributeFromJSONTyped = IdentityAttributeFromJSONTyped;
function IdentityAttributeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'required': value.required,
        'type': value.type,
    };
}
exports.IdentityAttributeToJSON = IdentityAttributeToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataProductPermissionsEntityToJSON = exports.DataProductPermissionsEntityFromJSONTyped = exports.DataProductPermissionsEntityFromJSON = void 0;
var runtime_1 = require("../runtime");
function DataProductPermissionsEntityFromJSON(json) {
    return DataProductPermissionsEntityFromJSONTyped(json, false);
}
exports.DataProductPermissionsEntityFromJSON = DataProductPermissionsEntityFromJSON;
function DataProductPermissionsEntityFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return __assign(__assign({}, json), { 'createdBy': !runtime_1.exists(json, 'createdBy') ? undefined : json['createdBy'], 'createdTimestamp': !runtime_1.exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'], 'updatedBy': !runtime_1.exists(json, 'updatedBy') ? undefined : json['updatedBy'], 'updatedTimestamp': !runtime_1.exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'] });
}
exports.DataProductPermissionsEntityFromJSONTyped = DataProductPermissionsEntityFromJSONTyped;
function DataProductPermissionsEntityToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return __assign(__assign({}, value), { 'createdBy': value.createdBy, 'createdTimestamp': value.createdTimestamp, 'updatedBy': value.updatedBy, 'updatedTimestamp': value.updatedTimestamp });
}
exports.DataProductPermissionsEntityToJSON = DataProductPermissionsEntityToJSON;

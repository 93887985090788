"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScriptVulnerabilityReportMetricsToJSON = exports.ScriptVulnerabilityReportMetricsFromJSONTyped = exports.ScriptVulnerabilityReportMetricsFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ScriptVulnerabilityReportMetricsFromJSON(json) {
    return ScriptVulnerabilityReportMetricsFromJSONTyped(json, false);
}
exports.ScriptVulnerabilityReportMetricsFromJSON = ScriptVulnerabilityReportMetricsFromJSON;
function ScriptVulnerabilityReportMetricsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'confidence': _1.ScriptVulnerabilityReportMetricsConfidenceFromJSON(json['confidence']),
        'loc': json['loc'],
        'nosec': json['nosec'],
        'severity': _1.ScriptVulnerabilityReportMetricsConfidenceFromJSON(json['severity']),
        'skippedTests': !runtime_1.exists(json, 'skippedTests') ? undefined : json['skippedTests'],
    };
}
exports.ScriptVulnerabilityReportMetricsFromJSONTyped = ScriptVulnerabilityReportMetricsFromJSONTyped;
function ScriptVulnerabilityReportMetricsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'confidence': _1.ScriptVulnerabilityReportMetricsConfidenceToJSON(value.confidence),
        'loc': value.loc,
        'nosec': value.nosec,
        'severity': _1.ScriptVulnerabilityReportMetricsConfidenceToJSON(value.severity),
        'skippedTests': value.skippedTests,
    };
}
exports.ScriptVulnerabilityReportMetricsToJSON = ScriptVulnerabilityReportMetricsToJSON;

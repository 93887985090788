"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScriptInputToJSON = exports.ScriptInputFromJSONTyped = exports.ScriptInputFromJSON = void 0;
var runtime_1 = require("../runtime");
function ScriptInputFromJSON(json) {
    return ScriptInputFromJSONTyped(json, false);
}
exports.ScriptInputFromJSON = ScriptInputFromJSON;
function ScriptInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'helperText': !runtime_1.exists(json, 'helperText') ? undefined : json['helperText'],
        'inputSchema': !runtime_1.exists(json, 'inputSchema') ? undefined : json['inputSchema'],
        'name': json['name'],
        'namespace': !runtime_1.exists(json, 'namespace') ? undefined : json['namespace'],
        'scriptId': !runtime_1.exists(json, 'scriptId') ? undefined : json['scriptId'],
        'source': json['source'],
        'updatedTimestamp': !runtime_1.exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
        'versionId': !runtime_1.exists(json, 'versionId') ? undefined : json['versionId'],
    };
}
exports.ScriptInputFromJSONTyped = ScriptInputFromJSONTyped;
function ScriptInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'description': value.description,
        'helperText': value.helperText,
        'inputSchema': value.inputSchema,
        'name': value.name,
        'namespace': value.namespace,
        'scriptId': value.scriptId,
        'source': value.source,
        'updatedTimestamp': value.updatedTimestamp,
        'versionId': value.versionId,
    };
}
exports.ScriptInputToJSON = ScriptInputToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GovernedDataSetToJSON = exports.GovernedDataSetFromJSONTyped = exports.GovernedDataSetFromJSON = void 0;
var _1 = require("./");
function GovernedDataSetFromJSON(json) {
    return GovernedDataSetFromJSONTyped(json, false);
}
exports.GovernedDataSetFromJSON = GovernedDataSetFromJSON;
function GovernedDataSetFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'addressedAs': json['addressedAs'],
        'dataProductId': json['dataProductId'],
        'dataSet': _1.GovernedDataSetDetailsFromJSON(json['dataSet']),
        'dataSetId': json['dataSetId'],
        'domainId': json['domainId'],
    };
}
exports.GovernedDataSetFromJSONTyped = GovernedDataSetFromJSONTyped;
function GovernedDataSetToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'addressedAs': value.addressedAs,
        'dataProductId': value.dataProductId,
        'dataSet': _1.GovernedDataSetDetailsToJSON(value.dataSet),
        'dataSetId': value.dataSetId,
        'domainId': value.domainId,
    };
}
exports.GovernedDataSetToJSON = GovernedDataSetToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScriptSourceValidationOutputToJSON = exports.ScriptSourceValidationOutputFromJSONTyped = exports.ScriptSourceValidationOutputFromJSON = void 0;
var _1 = require("./");
function ScriptSourceValidationOutputFromJSON(json) {
    return ScriptSourceValidationOutputFromJSONTyped(json, false);
}
exports.ScriptSourceValidationOutputFromJSON = ScriptSourceValidationOutputFromJSON;
function ScriptSourceValidationOutputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'report': _1.ScriptVulnerabilityReportFromJSON(json['report']),
        'scriptSource': json['scriptSource'],
    };
}
exports.ScriptSourceValidationOutputFromJSONTyped = ScriptSourceValidationOutputFromJSONTyped;
function ScriptSourceValidationOutputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'report': _1.ScriptVulnerabilityReportToJSON(value.report),
        'scriptSource': value.scriptSource,
    };
}
exports.ScriptSourceValidationOutputToJSON = ScriptSourceValidationOutputToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SavedQueryInputToJSON = exports.SavedQueryInputFromJSONTyped = exports.SavedQueryInputFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function SavedQueryInputFromJSON(json) {
    return SavedQueryInputFromJSONTyped(json, false);
}
exports.SavedQueryInputFromJSON = SavedQueryInputFromJSON;
function SavedQueryInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'addressedAs': !runtime_1.exists(json, 'addressedAs') ? undefined : json['addressedAs'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'namespace': !runtime_1.exists(json, 'namespace') ? undefined : json['namespace'],
        'query': json['query'],
        'queryId': !runtime_1.exists(json, 'queryId') ? undefined : json['queryId'],
        'referencedDataSets': !runtime_1.exists(json, 'referencedDataSets') ? undefined : (json['referencedDataSets'].map(_1.ReferencedDataSetIdentifierFromJSON)),
        'referencedQueries': !runtime_1.exists(json, 'referencedQueries') ? undefined : (json['referencedQueries'].map(_1.SavedQueryIdentifierFromJSON)),
        'tags': !runtime_1.exists(json, 'tags') ? undefined : _1.TagsFromJSON(json['tags']),
        'type': !runtime_1.exists(json, 'type') ? undefined : json['type'],
        'updatedTimestamp': !runtime_1.exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
    };
}
exports.SavedQueryInputFromJSONTyped = SavedQueryInputFromJSONTyped;
function SavedQueryInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'addressedAs': value.addressedAs,
        'description': value.description,
        'namespace': value.namespace,
        'query': value.query,
        'queryId': value.queryId,
        'referencedDataSets': value.referencedDataSets === undefined ? undefined : (value.referencedDataSets.map(_1.ReferencedDataSetIdentifierToJSON)),
        'referencedQueries': value.referencedQueries === undefined ? undefined : (value.referencedQueries.map(_1.SavedQueryIdentifierToJSON)),
        'tags': _1.TagsToJSON(value.tags),
        'type': value.type,
        'updatedTimestamp': value.updatedTimestamp,
    };
}
exports.SavedQueryInputToJSON = SavedQueryInputToJSON;

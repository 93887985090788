"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCostOutputTotalToJSON = exports.GetCostOutputTotalFromJSONTyped = exports.GetCostOutputTotalFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GetCostOutputTotalFromJSON(json) {
    return GetCostOutputTotalFromJSONTyped(json, false);
}
exports.GetCostOutputTotalFromJSON = GetCostOutputTotalFromJSON;
function GetCostOutputTotalFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'blendedCost': !runtime_1.exists(json, 'blendedCost') ? undefined : (runtime_1.mapValues(json['blendedCost'], _1.MetricValueFromJSON)),
        'usageQuantity': !runtime_1.exists(json, 'usageQuantity') ? undefined : (runtime_1.mapValues(json['usageQuantity'], _1.MetricValueFromJSON)),
    };
}
exports.GetCostOutputTotalFromJSONTyped = GetCostOutputTotalFromJSONTyped;
function GetCostOutputTotalToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'blendedCost': value.blendedCost === undefined ? undefined : (runtime_1.mapValues(value.blendedCost, _1.MetricValueToJSON)),
        'usageQuantity': value.usageQuantity === undefined ? undefined : (runtime_1.mapValues(value.usageQuantity, _1.MetricValueToJSON)),
    };
}
exports.GetCostOutputTotalToJSON = GetCostOutputTotalToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryRewriteInputToJSON = exports.QueryRewriteInputFromJSONTyped = exports.QueryRewriteInputFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function QueryRewriteInputFromJSON(json) {
    return QueryRewriteInputFromJSONTyped(json, false);
}
exports.QueryRewriteInputFromJSON = QueryRewriteInputFromJSON;
function QueryRewriteInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dataProducts': (runtime_1.mapValues(json['dataProducts'], _1.QueryRewriteInputDataProductsFromJSON)),
        'query': json['query'],
        'querySubstitutions': !runtime_1.exists(json, 'querySubstitutions') ? undefined : (runtime_1.mapValues(json['querySubstitutions'], _1.QueryRewriteInputQuerySubstitutionsFromJSON)),
    };
}
exports.QueryRewriteInputFromJSONTyped = QueryRewriteInputFromJSONTyped;
function QueryRewriteInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dataProducts': (runtime_1.mapValues(value.dataProducts, _1.QueryRewriteInputDataProductsToJSON)),
        'query': value.query,
        'querySubstitutions': value.querySubstitutions === undefined ? undefined : (runtime_1.mapValues(value.querySubstitutions, _1.QueryRewriteInputQuerySubstitutionsToJSON)),
    };
}
exports.QueryRewriteInputToJSON = QueryRewriteInputToJSON;

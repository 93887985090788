"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BudgetDetailsToJSON = exports.BudgetDetailsFromJSONTyped = exports.BudgetDetailsFromJSON = void 0;
var _1 = require("./");
function BudgetDetailsFromJSON(json) {
    return BudgetDetailsFromJSONTyped(json, false);
}
exports.BudgetDetailsFromJSON = BudgetDetailsFromJSON;
function BudgetDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'actualSpend': json['actualSpend'],
        'budgetLimit': json['budgetLimit'],
        'forecastedSpend': json['forecastedSpend'],
        'softNotifications': (json['softNotifications'].map(_1.BudgetDetailsSoftNotificationsFromJSON)),
        'subscriberList': json['subscriberList'],
    };
}
exports.BudgetDetailsFromJSONTyped = BudgetDetailsFromJSONTyped;
function BudgetDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'actualSpend': value.actualSpend,
        'budgetLimit': value.budgetLimit,
        'forecastedSpend': value.forecastedSpend,
        'softNotifications': (value.softNotifications.map(_1.BudgetDetailsSoftNotificationsToJSON)),
        'subscriberList': value.subscriberList,
    };
}
exports.BudgetDetailsToJSON = BudgetDetailsToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenToJSON = exports.TokenFromJSONTyped = exports.TokenFromJSON = void 0;
var runtime_1 = require("../runtime");
function TokenFromJSON(json) {
    return TokenFromJSONTyped(json, false);
}
exports.TokenFromJSON = TokenFromJSON;
function TokenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'authToken': !runtime_1.exists(json, 'authToken') ? undefined : json['authToken'],
        'authUrl': !runtime_1.exists(json, 'authUrl') ? undefined : json['authUrl'],
        'clientId': json['clientId'],
        'clientSecret': !runtime_1.exists(json, 'clientSecret') ? undefined : json['clientSecret'],
        'enabled': json['enabled'],
        'expiration': json['expiration'],
        'machineId': json['machineId'],
        'tokenId': json['tokenId'],
        'username': json['username'],
    };
}
exports.TokenFromJSONTyped = TokenFromJSONTyped;
function TokenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'authToken': value.authToken,
        'authUrl': value.authUrl,
        'clientId': value.clientId,
        'clientSecret': value.clientSecret,
        'enabled': value.enabled,
        'expiration': value.expiration,
        'machineId': value.machineId,
        'tokenId': value.tokenId,
        'username': value.username,
    };
}
exports.TokenToJSON = TokenToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BudgetInputToJSON = exports.BudgetInputFromJSONTyped = exports.BudgetInputFromJSON = void 0;
var runtime_1 = require("../runtime");
function BudgetInputFromJSON(json) {
    return BudgetInputFromJSONTyped(json, false);
}
exports.BudgetInputFromJSON = BudgetInputFromJSON;
function BudgetInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'budgetLimit': json['budgetLimit'],
        'softNotifications': json['softNotifications'],
        'subscriberList': json['subscriberList'],
        'updatedTimestamp': !runtime_1.exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
    };
}
exports.BudgetInputFromJSONTyped = BudgetInputFromJSONTyped;
function BudgetInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'budgetLimit': value.budgetLimit,
        'softNotifications': value.softNotifications,
        'subscriberList': value.subscriberList,
        'updatedTimestamp': value.updatedTimestamp,
    };
}
exports.BudgetInputToJSON = BudgetInputToJSON;

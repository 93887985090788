"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCostOutputGroupDefinitionsToJSON = exports.GetCostOutputGroupDefinitionsFromJSONTyped = exports.GetCostOutputGroupDefinitionsFromJSON = void 0;
var runtime_1 = require("../runtime");
function GetCostOutputGroupDefinitionsFromJSON(json) {
    return GetCostOutputGroupDefinitionsFromJSONTyped(json, false);
}
exports.GetCostOutputGroupDefinitionsFromJSON = GetCostOutputGroupDefinitionsFromJSON;
function GetCostOutputGroupDefinitionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'key': !runtime_1.exists(json, 'key') ? undefined : json['key'],
        'type': !runtime_1.exists(json, 'type') ? undefined : json['type'],
    };
}
exports.GetCostOutputGroupDefinitionsFromJSONTyped = GetCostOutputGroupDefinitionsFromJSONTyped;
function GetCostOutputGroupDefinitionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'key': value.key,
        'type': value.type,
    };
}
exports.GetCostOutputGroupDefinitionsToJSON = GetCostOutputGroupDefinitionsToJSON;

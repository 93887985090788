"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OntologyEntityToJSON = exports.OntologyEntityFromJSONTyped = exports.OntologyEntityFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function OntologyEntityFromJSON(json) {
    return OntologyEntityFromJSONTyped(json, false);
}
exports.OntologyEntityFromJSON = OntologyEntityFromJSON;
function OntologyEntityFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aliases': (json['aliases'].map(_1.OntologyAliasFromJSON)),
        'createdBy': !runtime_1.exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdTimestamp': !runtime_1.exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'],
        'defaultLens': !runtime_1.exists(json, 'defaultLens') ? undefined : _1.LensEnumFromJSON(json['defaultLens']),
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'name': json['name'],
        'ontologyId': json['ontologyId'],
        'ontologyNamespace': json['ontologyNamespace'],
        'updatedBy': !runtime_1.exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'updatedTimestamp': !runtime_1.exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
    };
}
exports.OntologyEntityFromJSONTyped = OntologyEntityFromJSONTyped;
function OntologyEntityToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aliases': (value.aliases.map(_1.OntologyAliasToJSON)),
        'createdBy': value.createdBy,
        'createdTimestamp': value.createdTimestamp,
        'defaultLens': _1.LensEnumToJSON(value.defaultLens),
        'description': value.description,
        'name': value.name,
        'ontologyId': value.ontologyId,
        'ontologyNamespace': value.ontologyNamespace,
        'updatedBy': value.updatedBy,
        'updatedTimestamp': value.updatedTimestamp,
    };
}
exports.OntologyEntityToJSON = OntologyEntityToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessRequestInputToJSON = exports.AccessRequestInputFromJSONTyped = exports.AccessRequestInputFromJSON = void 0;
var runtime_1 = require("../runtime");
function AccessRequestInputFromJSON(json) {
    return AccessRequestInputFromJSONTyped(json, false);
}
exports.AccessRequestInputFromJSON = AccessRequestInputFromJSON;
function AccessRequestInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'groupId': !runtime_1.exists(json, 'groupId') ? undefined : json['groupId'],
        'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
        'updatedTimestamp': !runtime_1.exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
        'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId'],
    };
}
exports.AccessRequestInputFromJSONTyped = AccessRequestInputFromJSONTyped;
function AccessRequestInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'groupId': value.groupId,
        'message': value.message,
        'updatedTimestamp': value.updatedTimestamp,
        'userId': value.userId,
    };
}
exports.AccessRequestInputToJSON = AccessRequestInputToJSON;

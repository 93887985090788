"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessRequestToJSON = exports.AccessRequestFromJSONTyped = exports.AccessRequestFromJSON = void 0;
var runtime_1 = require("../runtime");
function AccessRequestFromJSON(json) {
    return AccessRequestFromJSONTyped(json, false);
}
exports.AccessRequestFromJSON = AccessRequestFromJSON;
function AccessRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'groupId': json['groupId'],
        'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
        'userId': json['userId'],
    };
}
exports.AccessRequestFromJSONTyped = AccessRequestFromJSONTyped;
function AccessRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'groupId': value.groupId,
        'message': value.message,
        'userId': value.userId,
    };
}
exports.AccessRequestToJSON = AccessRequestToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributeValuePolicyToJSON = exports.AttributeValuePolicyFromJSONTyped = exports.AttributeValuePolicyFromJSON = void 0;
var runtime_1 = require("../runtime");
function AttributeValuePolicyFromJSON(json) {
    return AttributeValuePolicyFromJSONTyped(json, false);
}
exports.AttributeValuePolicyFromJSON = AttributeValuePolicyFromJSON;
function AttributeValuePolicyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'group': json['group'],
        'namespaceAndAttributeId': json['namespaceAndAttributeId'],
        'sqlClause': json['sqlClause'],
    };
}
exports.AttributeValuePolicyFromJSONTyped = AttributeValuePolicyFromJSONTyped;
function AttributeValuePolicyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'description': value.description,
        'group': value.group,
        'namespaceAndAttributeId': value.namespaceAndAttributeId,
        'sqlClause': value.sqlClause,
    };
}
exports.AttributeValuePolicyToJSON = AttributeValuePolicyToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BudgetDetailsSoftNotificationsToJSON = exports.BudgetDetailsSoftNotificationsFromJSONTyped = exports.BudgetDetailsSoftNotificationsFromJSON = void 0;
function BudgetDetailsSoftNotificationsFromJSON(json) {
    return BudgetDetailsSoftNotificationsFromJSONTyped(json, false);
}
exports.BudgetDetailsSoftNotificationsFromJSON = BudgetDetailsSoftNotificationsFromJSON;
function BudgetDetailsSoftNotificationsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'state': json['state'],
        'threshold': json['threshold'],
    };
}
exports.BudgetDetailsSoftNotificationsFromJSONTyped = BudgetDetailsSoftNotificationsFromJSONTyped;
function BudgetDetailsSoftNotificationsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'state': value.state,
        'threshold': value.threshold,
    };
}
exports.BudgetDetailsSoftNotificationsToJSON = BudgetDetailsSoftNotificationsToJSON;

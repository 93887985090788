"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultLensPolicyToJSON = exports.DefaultLensPolicyFromJSONTyped = exports.DefaultLensPolicyFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function DefaultLensPolicyFromJSON(json) {
    return DefaultLensPolicyFromJSONTyped(json, false);
}
exports.DefaultLensPolicyFromJSON = DefaultLensPolicyFromJSON;
function DefaultLensPolicyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dataProductId': json['dataProductId'],
        'defaultLensId': _1.LensEnumFromJSON(json['defaultLensId']),
        'defaultLensOverrides': (runtime_1.mapValues(json['defaultLensOverrides'], _1.LensEnumFromJSON)),
        'domainId': json['domainId'],
    };
}
exports.DefaultLensPolicyFromJSONTyped = DefaultLensPolicyFromJSONTyped;
function DefaultLensPolicyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dataProductId': value.dataProductId,
        'defaultLensId': _1.LensEnumToJSON(value.defaultLensId),
        'defaultLensOverrides': (runtime_1.mapValues(value.defaultLensOverrides, _1.LensEnumToJSON)),
        'domainId': value.domainId,
    };
}
exports.DefaultLensPolicyToJSON = DefaultLensPolicyToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataProductPolicyEntityToJSON = exports.DataProductPolicyEntityFromJSONTyped = exports.DataProductPolicyEntityFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function DataProductPolicyEntityFromJSON(json) {
    return DataProductPolicyEntityFromJSONTyped(json, false);
}
exports.DataProductPolicyEntityFromJSON = DataProductPolicyEntityFromJSON;
function DataProductPolicyEntityFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'createdBy': !runtime_1.exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdTimestamp': !runtime_1.exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'],
        'dataProductId': json['dataProductId'],
        'domainId': json['domainId'],
        'permissions': _1.DataProductPermissionsFromJSON(json['permissions']),
        'updatedBy': !runtime_1.exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'updatedTimestamp': !runtime_1.exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
    };
}
exports.DataProductPolicyEntityFromJSONTyped = DataProductPolicyEntityFromJSONTyped;
function DataProductPolicyEntityToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'createdBy': value.createdBy,
        'createdTimestamp': value.createdTimestamp,
        'dataProductId': value.dataProductId,
        'domainId': value.domainId,
        'permissions': _1.DataProductPermissionsToJSON(value.permissions),
        'updatedBy': value.updatedBy,
        'updatedTimestamp': value.updatedTimestamp,
    };
}
exports.DataProductPolicyEntityToJSON = DataProductPolicyEntityToJSON;

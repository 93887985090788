"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginatedQueryResultAsAthenaToJSON = exports.PaginatedQueryResultAsAthenaFromJSONTyped = exports.PaginatedQueryResultAsAthenaFromJSON = void 0;
var runtime_1 = require("../runtime");
function PaginatedQueryResultAsAthenaFromJSON(json) {
    return PaginatedQueryResultAsAthenaFromJSONTyped(json, false);
}
exports.PaginatedQueryResultAsAthenaFromJSON = PaginatedQueryResultAsAthenaFromJSON;
function PaginatedQueryResultAsAthenaFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nextToken': !runtime_1.exists(json, 'nextToken') ? undefined : json['nextToken'],
        'resultSet': !runtime_1.exists(json, 'resultSet') ? undefined : json['resultSet'],
        'updateCount': !runtime_1.exists(json, 'updateCount') ? undefined : json['updateCount'],
    };
}
exports.PaginatedQueryResultAsAthenaFromJSONTyped = PaginatedQueryResultAsAthenaFromJSONTyped;
function PaginatedQueryResultAsAthenaToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nextToken': value.nextToken,
        'resultSet': value.resultSet,
        'updateCount': value.updateCount,
    };
}
exports.PaginatedQueryResultAsAthenaToJSON = PaginatedQueryResultAsAthenaToJSON;

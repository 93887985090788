"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataProductMultipartFileUploadStartedToJSON = exports.DataProductMultipartFileUploadStartedFromJSONTyped = exports.DataProductMultipartFileUploadStartedFromJSON = void 0;
function DataProductMultipartFileUploadStartedFromJSON(json) {
    return DataProductMultipartFileUploadStartedFromJSONTyped(json, false);
}
exports.DataProductMultipartFileUploadStartedFromJSON = DataProductMultipartFileUploadStartedFromJSON;
function DataProductMultipartFileUploadStartedFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bucket': json['bucket'],
        'key': json['key'],
        'uploadId': json['uploadId'],
    };
}
exports.DataProductMultipartFileUploadStartedFromJSONTyped = DataProductMultipartFileUploadStartedFromJSONTyped;
function DataProductMultipartFileUploadStartedToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bucket': value.bucket,
        'key': value.key,
        'uploadId': value.uploadId,
    };
}
exports.DataProductMultipartFileUploadStartedToJSON = DataProductMultipartFileUploadStartedToJSON;

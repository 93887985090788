"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryHistoryEntityToJSON = exports.QueryHistoryEntityFromJSONTyped = exports.QueryHistoryEntityFromJSON = void 0;
var runtime_1 = require("../runtime");
function QueryHistoryEntityFromJSON(json) {
    return QueryHistoryEntityFromJSONTyped(json, false);
}
exports.QueryHistoryEntityFromJSON = QueryHistoryEntityFromJSON;
function QueryHistoryEntityFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'createdBy': !runtime_1.exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdTimestamp': !runtime_1.exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'],
        'executionId': json['executionId'],
        'query': json['query'],
        'updatedBy': !runtime_1.exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'updatedTimestamp': !runtime_1.exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
    };
}
exports.QueryHistoryEntityFromJSONTyped = QueryHistoryEntityFromJSONTyped;
function QueryHistoryEntityToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'createdBy': value.createdBy,
        'createdTimestamp': value.createdTimestamp,
        'executionId': value.executionId,
        'query': value.query,
        'updatedBy': value.updatedBy,
        'updatedTimestamp': value.updatedTimestamp,
    };
}
exports.QueryHistoryEntityToJSON = QueryHistoryEntityToJSON;

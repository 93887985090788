"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributePolicyInputToJSON = exports.AttributePolicyInputFromJSONTyped = exports.AttributePolicyInputFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function AttributePolicyInputFromJSON(json) {
    return AttributePolicyInputFromJSONTyped(json, false);
}
exports.AttributePolicyInputFromJSON = AttributePolicyInputFromJSON;
function AttributePolicyInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'group': !runtime_1.exists(json, 'group') ? undefined : json['group'],
        'lensId': _1.LensEnumFromJSON(json['lensId']),
        'namespaceAndAttributeId': !runtime_1.exists(json, 'namespaceAndAttributeId') ? undefined : json['namespaceAndAttributeId'],
        'updatedTimestamp': !runtime_1.exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
    };
}
exports.AttributePolicyInputFromJSONTyped = AttributePolicyInputFromJSONTyped;
function AttributePolicyInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'group': value.group,
        'lensId': _1.LensEnumToJSON(value.lensId),
        'namespaceAndAttributeId': value.namespaceAndAttributeId,
        'updatedTimestamp': value.updatedTimestamp,
    };
}
exports.AttributePolicyInputToJSON = AttributePolicyInputToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GovernedColumnMetadataToJSON = exports.GovernedColumnMetadataFromJSONTyped = exports.GovernedColumnMetadataFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GovernedColumnMetadataFromJSON(json) {
    return GovernedColumnMetadataFromJSONTyped(json, false);
}
exports.GovernedColumnMetadataFromJSON = GovernedColumnMetadataFromJSON;
function GovernedColumnMetadataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dataType': json['dataType'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'lensToApply': _1.LensEnumFromJSON(json['lensToApply']),
        'ontologyAttributeId': !runtime_1.exists(json, 'ontologyAttributeId') ? undefined : json['ontologyAttributeId'],
        'ontologyNamespace': !runtime_1.exists(json, 'ontologyNamespace') ? undefined : json['ontologyNamespace'],
        'piiClassification': !runtime_1.exists(json, 'piiClassification') ? undefined : json['piiClassification'],
        'sortOrder': !runtime_1.exists(json, 'sortOrder') ? undefined : json['sortOrder'],
        'sqlClauses': json['sqlClauses'],
    };
}
exports.GovernedColumnMetadataFromJSONTyped = GovernedColumnMetadataFromJSONTyped;
function GovernedColumnMetadataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dataType': value.dataType,
        'description': value.description,
        'lensToApply': _1.LensEnumToJSON(value.lensToApply),
        'ontologyAttributeId': value.ontologyAttributeId,
        'ontologyNamespace': value.ontologyNamespace,
        'piiClassification': value.piiClassification,
        'sortOrder': value.sortOrder,
        'sqlClauses': value.sqlClauses,
    };
}
exports.GovernedColumnMetadataToJSON = GovernedColumnMetadataToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataProductInputToJSON = exports.DataProductInputFromJSONTyped = exports.DataProductInputFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function DataProductInputFromJSON(json) {
    return DataProductInputFromJSONTyped(json, false);
}
exports.DataProductInputFromJSON = DataProductInputFromJSON;
function DataProductInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'childDataProducts': !runtime_1.exists(json, 'childDataProducts') ? undefined : (json['childDataProducts'].map(_1.DataProductIdentifierFromJSON)),
        'cloudFormationStackId': !runtime_1.exists(json, 'cloudFormationStackId') ? undefined : json['cloudFormationStackId'],
        'dataImportStateMachineArn': !runtime_1.exists(json, 'dataImportStateMachineArn') ? undefined : json['dataImportStateMachineArn'],
        'dataProductId': !runtime_1.exists(json, 'dataProductId') ? undefined : json['dataProductId'],
        'dataSets': !runtime_1.exists(json, 'dataSets') ? undefined : _1.DataSetsFromJSON(json['dataSets']),
        'dataStatus': !runtime_1.exists(json, 'dataStatus') ? undefined : _1.DataProductDataStatusFromJSON(json['dataStatus']),
        'dataStatusDetails': !runtime_1.exists(json, 'dataStatusDetails') ? undefined : json['dataStatusDetails'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'domainId': !runtime_1.exists(json, 'domainId') ? undefined : json['domainId'],
        'enableAutomaticPii': !runtime_1.exists(json, 'enableAutomaticPii') ? undefined : json['enableAutomaticPii'],
        'enableAutomaticTransforms': !runtime_1.exists(json, 'enableAutomaticTransforms') ? undefined : json['enableAutomaticTransforms'],
        'infrastructureStatus': !runtime_1.exists(json, 'infrastructureStatus') ? undefined : _1.DataProductInfrastructureStatusFromJSON(json['infrastructureStatus']),
        'infrastructureStatusDetails': !runtime_1.exists(json, 'infrastructureStatusDetails') ? undefined : json['infrastructureStatusDetails'],
        'latestDataUpdateTimestamp': !runtime_1.exists(json, 'latestDataUpdateTimestamp') ? undefined : json['latestDataUpdateTimestamp'],
        'name': json['name'],
        'parentDataProducts': !runtime_1.exists(json, 'parentDataProducts') ? undefined : (json['parentDataProducts'].map(_1.DataProductIdentifierFromJSON)),
        'sourceDataSets': !runtime_1.exists(json, 'sourceDataSets') ? undefined : _1.DataSetsFromJSON(json['sourceDataSets']),
        'sourceDataStatus': !runtime_1.exists(json, 'sourceDataStatus') ? undefined : _1.DataProductSourceDataStatusFromJSON(json['sourceDataStatus']),
        'sourceDataStatusDetails': !runtime_1.exists(json, 'sourceDataStatusDetails') ? undefined : json['sourceDataStatusDetails'],
        'sourceDetails': !runtime_1.exists(json, 'sourceDetails') ? undefined : json['sourceDetails'],
        'sourceType': _1.SourceTypeEnumFromJSON(json['sourceType']),
        'tags': _1.TagsFromJSON(json['tags']),
        'transforms': _1.DataProductTransformsFromJSON(json['transforms']),
        'updatedTimestamp': !runtime_1.exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
        'updateTrigger': _1.DataProductUpdateTriggerFromJSON(json['updateTrigger']),
    };
}
exports.DataProductInputFromJSONTyped = DataProductInputFromJSONTyped;
function DataProductInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'childDataProducts': value.childDataProducts === undefined ? undefined : (value.childDataProducts.map(_1.DataProductIdentifierToJSON)),
        'cloudFormationStackId': value.cloudFormationStackId,
        'dataImportStateMachineArn': value.dataImportStateMachineArn,
        'dataProductId': value.dataProductId,
        'dataSets': _1.DataSetsToJSON(value.dataSets),
        'dataStatus': _1.DataProductDataStatusToJSON(value.dataStatus),
        'dataStatusDetails': value.dataStatusDetails,
        'description': value.description,
        'domainId': value.domainId,
        'enableAutomaticPii': value.enableAutomaticPii,
        'enableAutomaticTransforms': value.enableAutomaticTransforms,
        'infrastructureStatus': _1.DataProductInfrastructureStatusToJSON(value.infrastructureStatus),
        'infrastructureStatusDetails': value.infrastructureStatusDetails,
        'latestDataUpdateTimestamp': value.latestDataUpdateTimestamp,
        'name': value.name,
        'parentDataProducts': value.parentDataProducts === undefined ? undefined : (value.parentDataProducts.map(_1.DataProductIdentifierToJSON)),
        'sourceDataSets': _1.DataSetsToJSON(value.sourceDataSets),
        'sourceDataStatus': _1.DataProductSourceDataStatusToJSON(value.sourceDataStatus),
        'sourceDataStatusDetails': value.sourceDataStatusDetails,
        'sourceDetails': value.sourceDetails,
        'sourceType': _1.SourceTypeEnumToJSON(value.sourceType),
        'tags': _1.TagsToJSON(value.tags),
        'transforms': _1.DataProductTransformsToJSON(value.transforms),
        'updatedTimestamp': value.updatedTimestamp,
        'updateTrigger': _1.DataProductUpdateTriggerToJSON(value.updateTrigger),
    };
}
exports.DataProductInputToJSON = DataProductInputToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OntologyInputToJSON = exports.OntologyInputFromJSONTyped = exports.OntologyInputFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function OntologyInputFromJSON(json) {
    return OntologyInputFromJSONTyped(json, false);
}
exports.OntologyInputFromJSON = OntologyInputFromJSON;
function OntologyInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aliases': (json['aliases'].map(_1.OntologyAliasFromJSON)),
        'defaultLens': !runtime_1.exists(json, 'defaultLens') ? undefined : _1.LensEnumFromJSON(json['defaultLens']),
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'name': json['name'],
        'ontologyId': !runtime_1.exists(json, 'ontologyId') ? undefined : json['ontologyId'],
        'ontologyNamespace': !runtime_1.exists(json, 'ontologyNamespace') ? undefined : json['ontologyNamespace'],
        'updatedTimestamp': !runtime_1.exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
    };
}
exports.OntologyInputFromJSONTyped = OntologyInputFromJSONTyped;
function OntologyInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aliases': (value.aliases.map(_1.OntologyAliasToJSON)),
        'defaultLens': _1.LensEnumToJSON(value.defaultLens),
        'description': value.description,
        'name': value.name,
        'ontologyId': value.ontologyId,
        'ontologyNamespace': value.ontologyNamespace,
        'updatedTimestamp': value.updatedTimestamp,
    };
}
exports.OntologyInputToJSON = OntologyInputToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListIdentityUsersResponseToJSON = exports.ListIdentityUsersResponseFromJSONTyped = exports.ListIdentityUsersResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ListIdentityUsersResponseFromJSON(json) {
    return ListIdentityUsersResponseFromJSONTyped(json, false);
}
exports.ListIdentityUsersResponseFromJSON = ListIdentityUsersResponseFromJSON;
function ListIdentityUsersResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nextToken': !runtime_1.exists(json, 'nextToken') ? undefined : json['nextToken'],
        'totalItems': !runtime_1.exists(json, 'totalItems') ? undefined : json['totalItems'],
        'users': (json['users'].map(_1.UserEntityFromJSON)),
    };
}
exports.ListIdentityUsersResponseFromJSONTyped = ListIdentityUsersResponseFromJSONTyped;
function ListIdentityUsersResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nextToken': value.nextToken,
        'totalItems': value.totalItems,
        'users': (value.users.map(_1.UserEntityToJSON)),
    };
}
exports.ListIdentityUsersResponseToJSON = ListIdentityUsersResponseToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetGovernancePolicyAttributesResponseToJSON = exports.GetGovernancePolicyAttributesResponseFromJSONTyped = exports.GetGovernancePolicyAttributesResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GetGovernancePolicyAttributesResponseFromJSON(json) {
    return GetGovernancePolicyAttributesResponseFromJSONTyped(json, false);
}
exports.GetGovernancePolicyAttributesResponseFromJSON = GetGovernancePolicyAttributesResponseFromJSON;
function GetGovernancePolicyAttributesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'attributeIdToLensId': (runtime_1.mapValues(json['attributeIdToLensId'], _1.LensEnumFromJSON)),
    };
}
exports.GetGovernancePolicyAttributesResponseFromJSONTyped = GetGovernancePolicyAttributesResponseFromJSONTyped;
function GetGovernancePolicyAttributesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'attributeIdToLensId': (runtime_1.mapValues(value.attributeIdToLensId, _1.LensEnumToJSON)),
    };
}
exports.GetGovernancePolicyAttributesResponseToJSON = GetGovernancePolicyAttributesResponseToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Automated Data Analytics on AWS API
 * Automated Data Analytics on AWS simplifies the management and analysis of data, providing an end-to-end platform used for ingesting, transforming, governing, and querying datasets through a standalone user interface
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryRewriteInputColumnsToJSON = exports.QueryRewriteInputColumnsFromJSONTyped = exports.QueryRewriteInputColumnsFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function QueryRewriteInputColumnsFromJSON(json) {
    return QueryRewriteInputColumnsFromJSONTyped(json, false);
}
exports.QueryRewriteInputColumnsFromJSON = QueryRewriteInputColumnsFromJSON;
function QueryRewriteInputColumnsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'attribute': !runtime_1.exists(json, 'attribute') ? undefined : json['attribute'],
        'clauses': json['clauses'],
        'name': json['name'],
        'udfs': (json['udfs'].map(_1.UDFFromJSON)),
    };
}
exports.QueryRewriteInputColumnsFromJSONTyped = QueryRewriteInputColumnsFromJSONTyped;
function QueryRewriteInputColumnsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'attribute': value.attribute,
        'clauses': value.clauses,
        'name': value.name,
        'udfs': (value.udfs.map(_1.UDFToJSON)),
    };
}
exports.QueryRewriteInputColumnsToJSON = QueryRewriteInputColumnsToJSON;
